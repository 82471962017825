import { useMemo } from "react";
import { InputsArrayTypes } from "../../../../Models/InputsArrayTypes";
const InputsArray = () => {
    const inputs: InputsArrayTypes[] = useMemo(() => {
        return [
            {
                id: 1,
                as: "",
                name: "name",
                label: "name",
                control: "input",
            },
        ]
    }, [])

    return { inputs }
}

export default InputsArray