import { FC } from "preact/compat"
import { createContext, ReactNode, useCallback, useState } from "react"


export const LoaderContext = createContext<{
    getIsModalLoading: () => boolean | null,
    setterIsModalLoading: (value: boolean | null) => void,
    getIsMainLoading: () => boolean | null,
    setterIsMainLoading: (value: boolean) => void
}>({
    getIsModalLoading: () => null,
    setterIsModalLoading: () => { },
    getIsMainLoading: () => true,
    setterIsMainLoading: () => { }
})

type LoaderContextProviderType = {
    children: ReactNode
}
const LoaderContextProvider: FC<LoaderContextProviderType> = ({ children }) => {
    const [isMainLoading, setIsMainLoading] = useState<boolean>(true)
    const [isModalLoading, setIsModalLoading] = useState<boolean | null>(null)


    const getIsModalLoading = useCallback(() => {
        return isModalLoading
    }, [isModalLoading])

    const setterIsModalLoading = useCallback((value: boolean | null) => {
        setIsModalLoading(value)
    }, [setIsModalLoading])


    const getIsMainLoading = useCallback(() => {
        return isMainLoading
    }, [isMainLoading])

    const setterIsMainLoading = useCallback((value: boolean) => {
        setIsMainLoading(value)
    }, [setIsMainLoading])


    return (
        <LoaderContext.Provider value={{
            getIsModalLoading,
            setterIsModalLoading,
            getIsMainLoading,
            setterIsMainLoading
        }}>
            {children}
        </LoaderContext.Provider>
    )
}


export default LoaderContextProvider