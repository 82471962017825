import React, { FC } from 'react'
import { NavLink } from 'react-router-dom'
import { LinksProps } from '../../Models/LinksProps'
import { useTranslation } from 'react-i18next'

type LinksWithPermissionsProps = {
    link: LinksProps,
    permissions: string[]
}

const LinksWithPermissions: FC<LinksWithPermissionsProps> = ({ link, permissions }) => {
    const { t } = useTranslation()
    if (permissions.includes(link.perm)) {
        return <li>
            <NavLink to={link.to} key={link.id} className="flex-start-center" end={true}>
                <span className="flex-center">
                    {React.createElement(link.icon)}
                </span>
                {t(link.title)}
            </NavLink>
        </li>
    }

    else {
        return <></>
    }
}

export default LinksWithPermissions