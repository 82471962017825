import { useMemo } from "react";
import { InputsArrayTypes } from "../../../../Models/InputsArrayTypes";
const InputsArrayEdit = () => {
    const inputs: InputsArrayTypes[] = useMemo(() => {
        return [
            {
                id: 1,
                control: "input",
                as: "",
                label: "course-name",
                name: "course_name"
            },
            {
                id: 2,
                control: "date-picker",
                label: "start",
                name: "start_date",
                dateFormat: "YYYY-MM-DD"
            },
            {
                id: 3,
                control: "date-picker",
                label: "end",
                name: "end_date",
                dateFormat: "YYYY-MM-DD"
            },
            {
                id: 4,
                control: "input",
                as: "textarea",
                label: "objectives",
                name: "objectives"
            },
            {
                id: 5,
                control: "input",
                as: "",
                label: "meet-link",
                name: "meet_link"
            },
            {
                id: 6,
                control: "input",
                as: "",
                label: "courses_platform_username",
                name: "courses_platform_username"
            },
            {
                id: 7,
                control: "input",
                as: "",
                label: "courses_platform_password",
                name: "courses_platform_password"
            },
            {
                id: 8,
                control: "input",
                as: "",
                label: "additional_information",
                name: "additional_information"
            },
            {
                id: 9,
                control: "select",
                label: "rate",
                name: "rate_count",
                arrayOfOptions: [
                    {
                        id: 1,
                        title: 1,
                        value: "1"
                    },
                    {
                        id: 2,
                        title: 2,
                        value: "2"
                    },
                    {
                        id: 3,
                        title: 3,
                        value: "3"
                    },
                    {
                        id: 4,
                        title: 4,
                        value: "4"
                    },
                    {
                        id: 5,
                        title: 5,
                        value: "5"
                    }
                ]
            },
            {
                id: 10,
                control: "input",
                as: "",
                label: "evaluation_after_course",
                name: "evaluation_after_course"
            },


        ]
    }, [])
    return { inputs }
}

export default InputsArrayEdit