import { useTranslation } from "react-i18next"
import useQueryGet from "../../../Custom Hooks/useQueryGet"
import LeavesServices from "../../../Services/LeavesServices"
import { useContext } from "react"
import { TableContext } from "../../../Context/TableContext"

const ShowModal = () => {
    const { t } = useTranslation()
    const { recordId } = useContext(TableContext)
    const [eventData] = useQueryGet(() => LeavesServices.getById(recordId), ["get-single-leave", recordId], undefined, {
        modalLoading: true
    })

    return (
        <>
            <div className="flex-start-center-row mb-3">
                <label className="mb-0 mx-2">{t("name")} : </label>
                <p>{eventData?.data?.data?.user?.name}</p>
            </div>
            <div className="flex-start-center-row mb-3">
                <label className="mb-0 mx-2">{t("Position")} : </label>
                <p>{eventData?.data?.data?.position}</p>
            </div>
            <div className="flex-start-center-row mb-3">
                <label className="mb-0 mx-2">{t("AdmissionDate")}: </label>
                <p>{eventData?.data?.data?.admission_date}</p>
            </div>
            <div className="flex-start-center-row mb-3">
                <label className="mb-0 mx-2">{t("BackToWorkDate")} : </label>
                <p>{eventData?.data?.data?.back_to_work_date}</p>
            </div>
            <div className="flex-start-center-row mb-3">
                <label className="mb-0 mx-2">{t("start")} : </label>
                <p>{eventData?.data?.data?.start_date}</p>
            </div>
            <div className="flex-start-center-row mb-3">
                <label className="mb-0 mx-2">{t("end")} : </label>
                <p>{eventData?.data?.data?.end_date}</p>
            </div>
        </>
    )
}

export default ShowModal