import { ToastContainer } from "react-toastify"
import "./LeavesRequests.css"
import { LeavesRequestsTable, PreviousAcceptedLeavesRequestsTable } from "../../../Sections/LeavesRequests"


const LeavesRequests = () => {
    return (
        <>
            <LeavesRequestsTable />
            <PreviousAcceptedLeavesRequestsTable />
            <ToastContainer />
        </>

    )
}

export default LeavesRequests