import { useContext } from "react"
import { UserContext } from "../../../../../Context/UserContext"
import { useTranslation } from "react-i18next"

type ItemsType = {
    id: number,
    label: string,
    data: string,
    isOptional?: boolean
}
const ShowArray = () => {
    const { user } = useContext(UserContext)
    const { t } = useTranslation()
    const items: ItemsType[] = [
        {
            id: 1,
            label: t("name"),
            data: user?.name
        },
        {
            id: 2,
            label: t("email"),
            data: user?.email
        },
        {
            id: 3,
            label: t("DateOfBirth"),
            data: user?.date_of_birth
        },
        {
            id: 4,
            label: t("mobile"),
            data: user?.mobile
        },
        {
            id: 5,
            label: t("visa-id-number"),
            data: user?.visa_id_number,
            isOptional: true
        },
        {
            id: 6,
            label: t("visa-expiry-date"),
            data: user?.visa_expiry_date,
            isOptional: true
        },
        {
            id: 7,
            label: t("labor-card-issue-date"),
            data: user?.labor_card_issue_date,
            isOptional: true
        },
        {
            id: 8,
            label: t("labor-card-expiry-date"),
            data: user?.labor_card_expiry_date,
            isOptional: true
        },
        {
            id: 9,
            label: t("BloodType"),
            data: user?.blood_type
        },
        {
            id: 10,
            label: t("OfficialDateOfEmployment"),
            data: user?.official_date_of_employment
        }
    ]
    return { items }
}

export default ShowArray