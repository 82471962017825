import * as Yup from "yup"
const addSchema = Yup.object().shape({
    date: Yup.string().required("date is required"),
    amount: Yup.string().required("amount is required"),
    description: Yup.string()
        .min(3, 'Minimum 3 symbols')
        .max(10000, 'Maximum 10000 symbols')
        .required('description is required'),

})
export default addSchema