import { ErrorMessage, Field, FieldProps } from "formik"
import "./FilesUpload.css"
import { FC, memo, useState } from "react"
import { useTranslation } from "react-i18next"
import FileSvg from "../../../Assets/Images/Dashboard/file-copy-svgrepo-com.svg"
import { RiPencilFill } from "react-icons/ri"
import { TextError } from "../../"
type FilesUploadProps = {
    label: string,
    name: string
}
const FilesUpload: FC<FilesUploadProps> = ({ label, name }) => {
    const { t } = useTranslation()
    const [fileName, setFileName] = useState<string>("")
    return (
        <>
            <Field name="image1">
                {({ field, form }: FieldProps<any>) => (
                    <>
                        <div className='img-label'>{t(label)}</div>
                        <label htmlFor="imgUpload" className="file-input-con bg-full">
                            <img src={FileSvg} alt="" className="mb-2 file-file" />
                            <span>{fileName != null && fileName}</span>
                            <div className="change-file-btn flex-center">
                                <RiPencilFill className="icon" />
                            </div>
                            <input id="imgUpload" {...field} type="file" onChange={(e) => {
                                setFileName(e.target.files![0].name)
                                form.setFieldValue(name, e.target.files![0])
                            }} />
                        </label>
                    </>
                )}
            </Field>
            <Field name={name} type="hidden" />
            <ErrorMessage name={name} component={TextError} />
        </>
    )
}

export default memo(FilesUpload) 