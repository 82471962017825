import { FC, useCallback, useContext } from "react"
import { FormikContainer } from "../../Forms"
import { InputsArrayEdit } from "../../../Assets/Data/Dashboard/Courses"
import { useTranslation } from "react-i18next"
import editSchema from "../../../Utils/Validations/ProfileMangement/Courses/editSchema"
import { FormikHelpers, FormikValues } from "formik"
import { useParams } from "react-router-dom"
import { UserContext } from "../../../Context/UserContext"
import useQueryPost from "../../../Custom Hooks/useQueryPost"
import CoursesService from "../../../Services/CoursesService"
import { TableContext } from "../../../Context/TableContext"
import useQueryGet from "../../../Custom Hooks/useQueryGet"

type EditModalProps = {
    handleClose: () => void
}

const initialValues = {
    start_date: "",
    end_date: "",
    course_name: "",
    objectives: "",
    meet_link: "",
    courses_platform_username: "",
    courses_platform_password: "",
    additional_information: "",
    evaluation_after_course: "",
    rate_count: "",
}
const EditModal: FC<EditModalProps> = ({ handleClose }) => {
    const { t } = useTranslation()
    const params = useParams()
    const { loginedUserId } = useContext(UserContext)
    const { recordId } = useContext(TableContext)
    const [handleEdit] = useQueryPost(CoursesService.edit, ["edit-course"], {
        success: t("edited-succssefully"),
    }, ["get-courses"], () => {
        handleClose()
    })
    const [course] = useQueryGet(() => CoursesService.find(recordId), ["get-single-course", recordId], undefined, {
        modalLoading: true
    })
    const onSubmit = useCallback((values: FormikValues, onSubmitProps: FormikHelpers<FormikValues>) => {
        handleEdit({ ...values, user_id: params.id ? params.id : loginedUserId, id: recordId })
            .then((_) => {
                onSubmitProps.setSubmitting(false)
            })
            .catch((_) => {
                onSubmitProps.setSubmitting(false)
            })
    }, [])

    return (
        <FormikContainer
            arrayOfInputs={InputsArrayEdit().inputs}
            btnText={t("edit")}
            className=""
            schema={editSchema}
            initialValues={course?.data?.data || initialValues}
            onSubmit={onSubmit}
            isEnableReinit={true}
        />
    )
}

export default EditModal