import { FC, useCallback, useMemo } from "react"
import useQueryPost from "../../../Custom Hooks/useQueryPost"
import UserService from "../../../Services/UserService"
import { FormikContainer } from "../../Forms"
import { FormikHelpers, FormikValues } from "formik"
import { InputsArray } from "../../../Assets/Data/Dashboard/ProfileMangement/FileMangement"
import { schema } from "../../../Utils/Validations/ProfileMangement/FileMangement/schema"
import { useTranslation } from "react-i18next"
type AddModalProps = {
    handleClose: (name: string) => void,
    id: number
}
const AddModal: FC<AddModalProps> = ({ handleClose, id }) => {
    const { t } = useTranslation()
    const [handleAdd,] = useQueryPost(UserService.addFile, ["add-file"], {
        success: t("added-succssefully"),
    }, ["get-all-files"], () => {
        handleClose("addFile")
    })
    const initialValues = useMemo(() => {
        return {
            files: [
                {
                    desc: "",
                    file: ""
                }
            ],
            user_id: id
        }
    }, [id])
    const onSubmit = useCallback((values: FormikValues, onSubmitProps: FormikHelpers<FormikValues>) => {
        handleAdd(values)
            .then((_) => {
                onSubmitProps.setSubmitting(false)
            })
            .catch((_) => {
                onSubmitProps.setSubmitting(false)
            })
    }, [])


    return (
        <FormikContainer
            className=""
            onSubmit={onSubmit}
            initialValues={initialValues}
            arrayOfInputs={InputsArray().inputs}
            schema={schema}
            btnText={t("add")}
        />
    )
}

export default AddModal