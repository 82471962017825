import { useContext } from "react"
import { useTranslation } from "react-i18next"
import { TableContext } from "../../Context/TableContext"
import useQueryGet from "../../Custom Hooks/useQueryGet"
import AdministrativeDecisionsService from "../../Services/AdministrativeDecisionsService"
import SpecialEventsService from "../../Services/SpecialEventsService"


const ShowModal = () => {
    const { t } = useTranslation()
    const { recordId } = useContext(TableContext)
    const [specialEvent] = useQueryGet(() => SpecialEventsService.show(recordId)
        , ["get-single-special-event", recordId], undefined, {
        modalLoading: true
    })
    return (
        <>
            <div className="flex-start-center-row mb-3 show-box">
                <label className="mb-0 mx-2">{t("Title")}  :  </label>
                <p>{specialEvent?.data?.data?.title}</p>
            </div>
            <div className="flex-start-center-row mb-3 show-box">
                <label className="mb-0 mx-2">{t("Body")} :  </label>
                <p>{specialEvent?.data?.data?.body}</p>
            </div>
            <div className="flex-start-center-row mb-3 show-box">
                <label className="mb-0 mx-2">{t("start")} :  </label>
                <p>{specialEvent?.data?.data?.start}</p>
            </div>
            <div className="flex-start-center-row mb-3 show-box">
                <label className="mb-0 mx-2">{t("end")} :  </label>
                <p>{specialEvent?.data?.data?.end}</p>
            </div>
        </>
    )
}

export default ShowModal