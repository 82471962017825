import { RouteObject } from "react-router-dom";
import { ChangePassword, Courses, EditProfile, FileMangement, Index, Profile, Salary } from "../../Pages";

const UserProfileRoutes: RouteObject[] = [
    {
        path: "userProfile/:id",
        Component: Index,
        children: [
            {
                index: true,
                Component: Profile,

            },
            {
                path: "edit-profile",
                Component: EditProfile,

            },
            {
                path: "File-Mangement",
                Component: FileMangement
            },

            {
                path: "Change-Password",
                Component: ChangePassword
            }
            ,
            {
                path: "Courses",
                Component: Courses
            },
            {
                path: "Salary",
                Component: Salary
            }
        ]

    },
]
export default UserProfileRoutes