import { useTranslation } from 'react-i18next'
import { AddModal, EditModal } from '../../../../Components/Department'
import { useMemo } from 'react'


const DepartmentsModals = (handleClose: (name: string) => void, t: (key: string) => string) => {
    const modals = useMemo(() => {
        return [
            {
                id: 1,
                name: "editDepartment",
                title: t("edit"),
                children: <EditModal handleClose={() => handleClose("editDepartment")} />
            },
            {
                id: 2,
                name: "addDepartment",
                title: t("add"),
                children: <AddModal handleClose={() => handleClose("addDepartment")} />
            },
        ]
    }, [handleClose])


    return { modals }
}

export default DepartmentsModals