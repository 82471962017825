import * as Yup from "yup"
const schema = Yup.object().shape({
    type: Yup.string().nullable(),
    name: Yup.string().nullable(),
    email: Yup.string().email('Wrong email format').nullable(),
    mobile: Yup.string().nullable(),
    visa_expiry_date: Yup.date().nullable(),
    visa_place_of_issue: Yup.string().nullable(),
    labor_card_issue_date: Yup.date().nullable(),
    labor_card_expiry_date: Yup.date().nullable(),
    employer: Yup.string().nullable(),
    official_date_of_employment: Yup.string().nullable(),
    date_of_birth: Yup.string().nullable()
})
export default schema