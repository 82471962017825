import { useTranslation } from "react-i18next"
import { BoxTitle, BoxWithShadow, DynamicTable, MainBtn, ModalComponent } from "../../../Components"
import "./UsersTable.css"
import { ToastContainer } from "react-toastify"
import useQueryGet from "../../../Custom Hooks/useQueryGet"
import UserService from "../../../Services/UserService"
import useModals from "../../../Custom Hooks/useModals"
import { UsersCols, UsersModals } from "../../../Assets/Data/Dashboard/Users"
import { useCallback } from "react"



const UsersTable = () => {
    const { t } = useTranslation()
    const [users, , , loading] = useQueryGet(UserService.getAll, ["get-all-users"])
    const { show, handleShow, handleClose } = useModals({
        addUser: false,
        editUser: false,
        resetPassword: false,
        Delete: false
    })
    const { UsersColsData } = UsersCols(handleShow)
    const { modals } = UsersModals(users?.data?.data, handleClose)

    const mainBtnFunc = useCallback(() => {
        handleShow("addUser")
    }, [])
    const memoizedHandleClose = useCallback((name: string) => {
        handleClose(name)
    }, [handleClose])
    return (
        <BoxWithShadow additionalClass="container-fluid users">
            <div className="flex-between-start">
                <BoxTitle title={t("user-mangement")} />
                <MainBtn title={t("add")} onClick={mainBtnFunc} />
            </div>
            <DynamicTable
                loading={loading}
                dataSource={users?.data?.data}
                columns={UsersColsData}
                pagination={false}
            />
            <ToastContainer />
            <ModalComponent show={show} handleClose={memoizedHandleClose} arrayOfModals={modals} />
        </BoxWithShadow>
    )
}

export default UsersTable