import { AuthAxios } from "./AxiosHandler"

class DeductionService {
    static endPoint = "deduction"
    static store(body: any) {
        return AuthAxios.post(`${DeductionService.endPoint}/store`, body)
    }
    static edit(body: any) {
        return AuthAxios.post(`${DeductionService.endPoint}/update`, body)
    }
    static show(id: number | null) {
        return AuthAxios.get(`${DeductionService.endPoint}/find/${id}`)
    }
    static delete(id: number) {
        return AuthAxios.post(`${DeductionService.endPoint}/delete/${id}`)
    }
}
export default DeductionService