
import "./Users.css"
import { EmployeChart, UsersTable } from "../../../Sections/Users";

const Users = () => {
    return (
        <>
            <EmployeChart />
            <UsersTable />
        </>

    )
}

export default Users