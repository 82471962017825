import React, { FC, memo } from "react"
import "./BoxWithShadow.css"
import { useTranslation } from "react-i18next"
type BoxWithShadowProps = {
    additionalClass: string,
    children?: React.ReactNode,
    image?: string
}
const BoxWithShadow: FC<BoxWithShadowProps> = ({ additionalClass, children, image }) => {
    const { i18n } = useTranslation()
    return (
        <div className={`box-with-shadow ${additionalClass} ${i18n.language == "ar" && "ar"}`} style={{
            backgroundImage: image !== null ? `url(https://crmapi.aetc.ae/${image})` : ""
        }}>
            {children}
        </div>
    )
}

export default memo(BoxWithShadow) 