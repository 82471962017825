import { useContext } from "react"
import { UserContext } from "../../Context/UserContext"
import { Navigate, Outlet } from "react-router-dom"

const PrivateRoutes = () => {
    const { token } = useContext(UserContext)
    return (
        <>
            {token ? <Outlet /> : <Navigate to="/" />}
        </>
    )
}

export default PrivateRoutes