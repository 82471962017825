import * as Yup from "yup"
const editSchema = Yup.object().shape({
    start_date: Yup.string().nullable(),
    end_date: Yup.string().nullable(),
    course_name: Yup.string()
        .min(3, 'Minimum 3 symbols')
        .max(300, 'Maximum 300 symbols')
        .nullable(),
    objectives: Yup.string()
        .min(3, 'Minimum 3 symbols')
        .max(10000, 'Maximum 10000 symbols')
        .nullable(),
    meet_link: Yup.string()
        .min(3, 'Minimum 3 symbols')
        .max(300, 'Maximum 300 symbols')
        .nullable(),
    courses_platform_username: Yup.string()
        .min(3, 'Minimum 3 symbols')
        .max(300, 'Maximum 300 symbols')
        .nullable(),
    courses_platform_password: Yup.string()
        .min(3, 'Minimum 3 symbols')
        .max(300, 'Maximum 300 symbols')
        .nullable(),
    additional_information: Yup.string()
        .min(3, 'Minimum 3 symbols')
        .max(300, 'Maximum 300 symbols')
        .nullable(),
})
export default editSchema