import React, { FC } from "react"
import "./DropDownMenu.css"
import { useTranslation } from "react-i18next"
type DropDownMenuProps = {
    arrayOfItems: any
    children?: React.ReactNode,
    className: string,
}
const DropDownMenu: FC<DropDownMenuProps> = ({ arrayOfItems, className, children }) => {

    const { i18n } = useTranslation()
    const items = arrayOfItems.map((item: any) => (
        <li className={`links ${item?.className}`} key={item.id} onClick={item?.onClick} >
            {item.children}
        </li>
    )
    )
    return (
        <>
            <div
                className={className}
                data-bs-auto-close="outside"
                data-bs-toggle="dropdown"
                aria-expanded="false">{children}</div>
            <ul className={`dropdown-menu ${i18n.language == "ar" && "ar"}`}>
                {items}
            </ul>
        </>
    )
}

export default DropDownMenu