import "./Login.css"
import { ToastContainer } from 'react-toastify';
import { useTranslation } from "react-i18next"
import { Design, LoginForm } from "../../Sections"

const Login = () => {
    const { i18n } = useTranslation()
    return (
        <div className={`container-fluid login ${i18n.language == "ar" && "ar"}`}>
            <div className="row row-cols-1 row-cols-md-2 p-0 m-0">
                <LoginForm />
                <Design />
            </div>
            <ToastContainer />
        </div>
    )
}

export default Login