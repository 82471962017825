import { useTranslation } from "react-i18next"
import { BoxTitle, BoxWithShadow } from "../../../Components"
import "./WordEventFromCompanyBox.css"
import { FormikContainer } from "../../../Components/Forms"
import { FormikHelpers, FormikValues } from "formik"
import WordEventSchema from "../../../Utils/Validations/EditMainPage/WordEventSchema"
import useQueryGet from "../../../Custom Hooks/useQueryGet"
import MainPageService from "../../../Services/MainPageService"
import useQueryPost from "../../../Custom Hooks/useQueryPost"
import { InputsArray2 } from "../../../Assets/Data/Dashboard/EditMainPage"
import { useCallback } from "react"
const initialValues = {
    daily_quote_en: "",
    daily_quote_ar: "",
    company_text_en: "",
    company_text_ar: "",
}
const WordEventFromCompanyBox = () => {
    const { t } = useTranslation()
    const [details,] = useQueryGet(MainPageService.get, ["get-main-page-details"], undefined, {
        mainLoading: true
    })
    const [handleSubmit] = useQueryPost(MainPageService.store, ["edit-main-page-details"], {
        success: t("edited-succssefully"),
    })
    const onSubmit = useCallback((values: FormikValues, onSubmitProps: FormikHelpers<FormikValues>) => {
        handleSubmit(values)
            .then((_) => {
                onSubmitProps.setSubmitting(false)
            })
            .catch((_) => {
                onSubmitProps.setSubmitting(false)
            })
    }, [])
    return (
        <>
            <BoxWithShadow additionalClass={` add-employer-of-the-month-box container-fluid mb-3`}>
                <BoxTitle title={t("Event&Wordfromcompany")} />
                <FormikContainer
                    className=""
                    arrayOfInputs={InputsArray2().inputs}
                    initialValues={details?.data?.data || initialValues}
                    isEnableReinit={true}
                    onSubmit={onSubmit}
                    btnText={t("edit")}
                    schema={WordEventSchema}
                />
            </BoxWithShadow>
        </>

    )
}

export default WordEventFromCompanyBox