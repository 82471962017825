import { Dispatch, SetStateAction } from 'react'

const CheckModalToOpen = (handleShow: (name: string) => void, clickedModalName: string, clickedId: number, setRecordId: Dispatch<SetStateAction<number | null>>) => {
    setRecordId(clickedId)
    if (clickedModalName == "Leave") {
        handleShow("showLeave")
    }
    else if (clickedModalName == "SpecialEvent") {
        handleShow("showSpecialEvent")
    }
    else if (clickedModalName == "leavesSuggestion") {
        handleShow("showLeavesSuggestion")
    }
    else if (clickedModalName == "OutdoorTask") {
        handleShow("showOutdoorTask")
    }
}

export default CheckModalToOpen