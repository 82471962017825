import { AxiosResponse } from "axios"
import Cookies from "js-cookie"
const Authintication = () => {
    const SignIn = (res: AxiosResponse | undefined) => {
        Cookies.set("token", res?.data.data.token)
        Cookies.set("id", res?.data.data.id)
        localStorage.setItem("avatar", res?.data.data.image)
        localStorage.setItem("name", res?.data.data.name)
        const permissions = res?.data?.data?.role_permissions.flatMap((item: any) => item.permissions)
        const uniquePermissions = new Set(permissions)
        const permissionsArray = Array.from(uniquePermissions)
        Cookies.set("permissions", JSON.stringify(permissionsArray))
        Cookies.set("baseSalary", res?.data.data.base_salary)
        window.location.assign("/dashboard")
    }
    const SignOut = () => {
        Cookies.remove("token")
        localStorage.removeItem("avatar")
        window.location.assign("/")
    }

    return { SignIn, SignOut }

}
export default Authintication