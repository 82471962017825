import { ErrorMessage, Field, FormikHelpers, FormikValues } from "formik"
import TextError from "../TextError/TextError"
import { useTranslation } from "react-i18next"
import useQueryPost from "../../Custom Hooks/useQueryPost"
import DepartmentsService from "../../Services/DepartmentsService"
import { FormikContainer } from "../Forms"
import schema from "../../Utils/Validations/Departments/schema"
import { FC, useCallback, useContext, useMemo } from "react"
import { TableContext } from "../../Context/TableContext"
import { InputsArray } from "../../Assets/Data/Dashboard/Departmens"
type EditModalProps = {
    handleClose: () => void
}
const EditModal: FC<EditModalProps> = ({ handleClose }) => {
    const { t } = useTranslation()
    const { recordId, record } = useContext(TableContext)
    const [handleSubmit,] = useQueryPost(DepartmentsService.editDepartment, ["edit-department"], {
        success: t("edited-succssefully"),
    }, ["get-departments"], () => {
        handleClose()
    })

    const initialValues = useMemo(() => {
        return {
            name: record?.name || ""
        }
    }, [record])
    const onSubmit = useCallback((values: FormikValues, onSubmitProps: FormikHelpers<FormikValues>) => {
        handleSubmit({ ...values, id: recordId })
            .then((_) => {
                onSubmitProps.setSubmitting(false)
            })
            .catch((_) => {
                onSubmitProps.setSubmitting(false)
            })
    }, [])

    return (
        <>
            <FormikContainer
                arrayOfInputs={InputsArray().inputs}
                className=""
                onSubmit={onSubmit}
                initialValues={initialValues}
                isEnableReinit={true}
                btnText={t("edit")}
                schema={schema} />
        </>
    )
}

export default EditModal