import { useParams } from "react-router-dom"
import useQueryGet from "../../../../../Custom Hooks/useQueryGet"
import { useContext, useMemo } from "react"
import UserService from "../../../../../Services/UserService"
import { UserContext } from "../../../../../Context/UserContext"
import { MdOutlineKeyboardDoubleArrowUp, MdOutlineKeyboardDoubleArrowDown, MdMinimize } from "react-icons/md";
type ItemsType = {
    id: number,
    title: string,
    icon: React.ReactNode,
    desc: string
}
const BannerBoxItems = () => {
    const params = useParams()
    const { loginedUserId } = useContext(UserContext)
    const [summary,] = useQueryGet(params.id != null ? () => UserService.getSummry(params.id) : () => UserService.getSummry(loginedUserId)
        , ["get-user-summary", params.id])
    const items: ItemsType[] = useMemo(() => {
        return [
            {
                id: 1,
                title: "BaseSalary",
                icon: <MdMinimize />,
                desc: summary?.data?.data?.base_salary
            },
            {
                id: 2,
                title: "TotalDeduction",
                icon: <MdOutlineKeyboardDoubleArrowDown className="faild-icon" />,
                desc: summary?.data?.data?.deductions_sum_amount
            },
            {
                id: 3,
                title: "TotalSalaryIncrease",
                icon: <MdOutlineKeyboardDoubleArrowUp className="success-icon" />,
                desc: summary?.data?.data?.salary_increases_sum_amount
            },
            {
                id: 4,
                title: "TotalBonus",
                icon: <MdOutlineKeyboardDoubleArrowUp className="success-icon" />,
                desc: summary?.data?.data?.bonuses_sum_amount
            }
        ]
    }, [summary])
    return { items }
}

export default BannerBoxItems