import { HiOutlineDotsVertical } from "react-icons/hi"
import BoxWithShadow from "../../../BoxWithShadow/BoxWithShadow"
import "./FileBox.css"
import { FC } from "react"
import DropDownMenu from "../../../DropDownMenu/DropDownMenu";
import { ActionsDropdown } from "../../../../Assets/Data/Dashboard/ProfileMangement/FileMangement";
import { useTranslation } from "react-i18next";
type FileBoxProps = {
    image: string,
    desc: string,
    path: string,
    id: number,
    handleShow: (name: string) => void
}
const FileBox: FC<FileBoxProps> = ({ image, desc, path, id, handleShow }) => {
    const { t } = useTranslation()
    return (
        <div className="col mb-5">
            <BoxWithShadow additionalClass="file-white-con">
                <img src={image} alt="" />
                <h2>{desc}</h2>
                <DropDownMenu
                    children={<HiOutlineDotsVertical />}
                    arrayOfItems={ActionsDropdown(id, path, desc, t, handleShow).items}
                    className="options-files"
                />
            </BoxWithShadow>
        </div>
    )
}

export default FileBox