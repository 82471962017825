import { useTranslation } from "react-i18next"
import { SalaryIncreasesInputs } from "../../../../Assets/Data/Dashboard/ProfileMangement/Salary"
import { FormikContainer } from "../../../Forms"
import { FormikHelpers, FormikValues } from "formik"
import editSchema from "../../../../Utils/Validations/ProfileMangement/Salary/SalaryIncreases/editSchema"
import useQueryPost from "../../../../Custom Hooks/useQueryPost"
import SalaryIncreaseService from "../../../../Services/SalaryIncreaseService"
import { FC, useCallback, useContext } from "react"
import { useParams } from "react-router-dom"
import { UserContext } from "../../../../Context/UserContext"
import { TableContext } from "../../../../Context/TableContext"
import useQueryGet from "../../../../Custom Hooks/useQueryGet"

const initialValues = {
    date: "",
    amount: ""
}

type EditModalProps = {
    handleClose: () => void
}

const EditModal: FC<EditModalProps> = ({ handleClose }) => {
    const { t } = useTranslation()
    const params = useParams()
    const { loginedUserId } = useContext(UserContext)
    const { recordId } = useContext(TableContext)
    const [handleEdit] = useQueryPost(SalaryIncreaseService.edit, ["edit-salary-increase"], {
        success: t("edited-succssefully"),
    }, ["get-payroll-details"], () => {
        handleClose()
    })
    const [salaryIncrease] = useQueryGet(() => SalaryIncreaseService.show(recordId), ["get-single-salary-icrease", recordId])
    const onSubmit = useCallback((values: FormikValues, onSubmitProps: FormikHelpers<FormikValues>) => {
        handleEdit({ ...values, user_id: params.id ? params.id : loginedUserId, id: recordId })
            .then((_) => {
                onSubmitProps.setSubmitting(false)
            })
            .catch((_) => {
                onSubmitProps.setSubmitting(false)
            })
    }, [])

    return (
        <FormikContainer
            arrayOfInputs={SalaryIncreasesInputs().inputs}
            schema={editSchema}
            className=""
            onSubmit={onSubmit}
            initialValues={salaryIncrease?.data?.data || initialValues}
            btnText={t("edit")}
            isEnableReinit={true}
        />
    )
}

export default EditModal