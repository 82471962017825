import { useContext } from "react"
import { useTranslation } from "react-i18next"
import { TableContext } from "../../Context/TableContext"
import useQueryGet from "../../Custom Hooks/useQueryGet"
import EmployerOfTheMonthService from "../../Services/EmployerOfTheMonthService"
import BoxWithShadow from "../BoxWithShadow/BoxWithShadow"

const ShowModal = () => {
    const { t, i18n } = useTranslation()
    const { recordId } = useContext(TableContext)
    const [data] = useQueryGet(() => EmployerOfTheMonthService.getSingleEmployer(recordId), ["get-single-employer-of-the-month", recordId],
        undefined, {
        modalLoading: true
    })
    return (
        <>
            <BoxWithShadow additionalClass="photo mb-5 bg-full" image={data?.data?.data?.user?.image} />
            <div className="flex-start-center-row mb-3 show-box">
                <label className="mb-0 mx-2">{t("name")} : </label>
                <p>{data?.data?.data?.user?.name} </p>
            </div>
            <div className="flex-start-center-row mb-3 show-box">
                <label className="mb-0 mx-2">{t("month")} :  </label>
                <p>{data?.data?.data?.month}</p>
            </div>
            <div className="flex-start-center-row mb-3 show-box">
                <label className="mb-0 mx-2">{t("message")} : </label>
                <p>{i18n.language == "en" ? data?.data?.data?.message_en : data?.data?.data?.message_ar} </p>
            </div>
        </>
    )
}

export default ShowModal