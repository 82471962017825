import PDFPhoto from "../Assets/Images/Dashboard/pdf.svg"
import ImagePhoto from "../Assets/Images/Dashboard/image-square-svgrepo-com.svg"
import DocPhoto from "../Assets/Images/Dashboard/doc.svg"
import FileSvg from "../Assets/Images/Dashboard/file-copy-svgrepo-com.svg"
const CheckExtinsion = (path: string) => {
    let image
    if (path.includes("pdf")) {
        image = PDFPhoto
    }
    else if (path.includes("jpg") || path.includes("jpeg")) {
        image = ImagePhoto
    }
    else if (path.includes("doc")) {
        image = DocPhoto
    }
    else {
        image = FileSvg
    }
    return image
}

export default CheckExtinsion