import * as Yup from "yup"
const AddEmpOfTheMonthSchema = Yup.object().shape({
    month: Yup.string().required(),
    user_id: Yup.string().required(),
    message_en: Yup.string()
        .min(1, 'message  must be at least 1 characters')
        .max(5000, 'message  cannot exceed 5000 characters')
        .required(),
    message_ar: Yup.string()
        .min(1, 'Arabic Message must be at least 1 characters')
        .max(5000, 'Arabic Message cannot exceed 5000 characters')
        .required()

})
export default AddEmpOfTheMonthSchema