import { AuthAxios } from "./AxiosHandler"

class AdministrativeDecisionsService {
    static endPoint = "administrative-decisions"
    static get(page: number) {
        return AuthAxios.get(`${AdministrativeDecisionsService.endPoint}?page=${page}`)
    }
    static store(body: any) {
        return AuthAxios.post(`${AdministrativeDecisionsService.endPoint}/store`, body)
    }
    static getSingleDecision(id: any) {
        return AuthAxios.get(`${AdministrativeDecisionsService.endPoint}/find/${id}`)
    }
    static editDecision(body: any) {
        return AuthAxios.post(`${AdministrativeDecisionsService.endPoint}/update`, body)
    }
    static deleteDecision(id: any) {
        return AuthAxios.post(`${AdministrativeDecisionsService.endPoint}/delete/${id}`)
    }
}
export default AdministrativeDecisionsService