import { useContext, useMemo } from 'react'
import { TableContext } from '../../../../../Context/TableContext'
import { useTranslation } from 'react-i18next'
import { AddModalDeduction, EditModalDeduction } from "../../../../../Components/ProfileMangement/Salary/Deduction"
import { AddModal, EditModal } from '../../../../../Components/ProfileMangement/Salary/SalaryIncreases'
import { AddBonusModal, EditBonusModal } from '../../../../../Components/ProfileMangement/Salary/Bonus'
import { DeleteModal } from '../../../../../Components'
import SalaryIncreaseService from '../../../../../Services/SalaryIncreaseService'
import DeductionService from '../../../../../Services/DeductionService'
import BonusService from '../../../../../Services/BonusService'

const SalaryModals = (handleClose: (name: string) => void) => {
    const { t } = useTranslation()
    const { tableName } = useContext(TableContext)

    const modals = useMemo(() => {
        return [
            {
                id: 1,
                name: "addSalaryIncreases",
                title: t("add"),
                children: <AddModal handleClose={() => handleClose("addSalaryIncreases")} />

            },
            {
                id: 2,
                name: "editSalaryIncreases",
                title: t("edit"),
                children: <EditModal handleClose={() => handleClose("editSalaryIncreases")} />
            },
            {
                id: 3,
                name: "addDeduction",
                title: t("add"),
                children: <AddModalDeduction handleClose={() => handleClose("addDeduction")} />
            },
            {
                id: 4,
                name: "editDeduction",
                title: t("edit"),
                children: <EditModalDeduction handleClose={() => handleClose("editDeduction")} />

            },
            {
                id: 5,
                name: "addBonus",
                title: t("add"),
                children: <AddBonusModal handleClose={() => handleClose("addBonus")} />
            },
            {
                id: 6,
                name: "editBonus",
                title: t("edit"),
                children: <EditBonusModal handleClose={() => handleClose("editBonus")} />
            },
            {
                id: 7,
                name: "Delete",
                title: t("Delete"),
                children: <DeleteModal
                    btnText={t("Delete")}
                    endPointConfig={{
                        invalidate: "get-payroll-details",
                        queryKey: ["delete"],
                        endPoint: tableName == "SalaryIncreases" ? SalaryIncreaseService.delete :
                            tableName == "Deduction" ? DeductionService.delete :
                                tableName == "Bouns" && BonusService.delete
                    }}
                    handleClose={() => handleClose("Delete")}

                    successMsg={t("deleted-succssefully")}
                />
            }
        ]
    }, [handleClose])


    return { modals }
}

export default SalaryModals