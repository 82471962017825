import { useMemo } from "react";
import { InputsArrayTypes } from "../../../../../Models/InputsArrayTypes";
const InputsArray = () => {
    const inputs: InputsArrayTypes[] = useMemo(() => {
        return [
            {
                id: 1,
                control: "input",
                as: "",
                label: "old-password",
                name: "old_password",
                isTwoCols: true
            },
            {
                id: 2,
                control: "input",
                as: "",
                label: "password",
                name: "password",
                isTwoCols: true
            },
            {
                id: 3,
                control: "input",
                as: "",
                label: "password-confirmation",
                name: "password_confirmation",
                isTwoCols: true
            }
        ]
    }, [])
    return { inputs }
}

export default InputsArray