import { LinksProps } from "../../../../Models/LinksProps"
import { PiUsersThreeDuotone } from "react-icons/pi";
import { CgProfile } from "react-icons/cg";
import { FaArchway, FaMoneyBillTransfer } from "react-icons/fa6";
import { RxDashboard } from "react-icons/rx";
import { CiCalendarDate } from "react-icons/ci";
import { BsCalendarCheck, BsFillHouseGearFill, BsCalendarEvent } from "react-icons/bs";
import { MdModeEditOutline, MdOutlineEventNote } from "react-icons/md";
import { TbCalendarTime } from "react-icons/tb";


const MainLinks: LinksProps[] = [

    {
        id: 1,
        to: "/dashboard",
        perm: "View Main Page",
        title: "Main-Page",
        icon: FaArchway
    },

    {
        id: 2,
        to: "HR-Dashbord",
        perm: "View HR Dashboard Page",
        title: "HR-Dashbord",
        icon: RxDashboard
    },
    {
        id: 3,
        to: "user-mangement",
        perm: "View User Management Page",
        title: "User-Mangement",
        icon: PiUsersThreeDuotone
    },
    {
        id: 4,
        to: "Profile-Mangement",
        perm: "View Profile Management Page",
        title: "Profile-Mangement",
        icon: CgProfile
    },
    {
        id: 5,
        to: "Calender",
        perm: "View Leaves Page",
        title: "Calender",
        icon: CiCalendarDate
    }
    ,
    {
        id: 6,
        to: "Leaves-Requests",
        perm: "Make Actions On Leaves Requests",
        title: "Leaves-Requests",
        icon: BsCalendarCheck
    }
    ,
    {
        id: 7,
        to: "Departments",
        perm: "Manage Department",
        title: "Departments",
        icon: BsFillHouseGearFill
    },
    {
        id: 8,
        to: "Edit-Main-Page",
        perm: "Update Main Page",
        title: "Edit-Main-Page",
        icon: MdModeEditOutline
    },
    {
        id: 9,
        to: "Outdoor-Tasks",
        perm: "Make Actions On Outdoor Task",
        title: "Outdoor-Tasks",
        icon: TbCalendarTime
    },
    {
        id: 10,
        to: "special-events",
        perm: "Manage Special Events",
        title: "special-events",
        icon: MdOutlineEventNote
    }
    ,
    {
        id: 11,
        to: "Leaves-Suggestion",
        perm: "Make Actions On Leaves Suggestion",
        title: "Leaves-Suggestion",
        icon: BsCalendarEvent
    },
    {
        id: 12,
        to: "Export-Salary",
        perm: "Make Actions On Salary",
        title: "Export-Salary",
        icon: FaMoneyBillTransfer
    }
]
export default MainLinks