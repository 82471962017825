
import { LinksProps } from "../../Models/LinksProps"
import { LinksWithPermissions } from "../"
import "./MenuItem.css"
import { FC } from "react"

type MenuItem = {
    link: LinksProps,
    permissions: string[]
}
const MenuItem: FC<MenuItem> = ({ link, permissions }) => {

    return (
        <>
            <LinksWithPermissions link={link} permissions={permissions} />
        </>
    )
}

export default MenuItem