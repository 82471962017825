import { DeleteModal } from '../../../../Components'
import { useTranslation } from 'react-i18next'
import LeavesSuggestionService from '../../../../Services/LeavesSuggestionService'
import { EditModal, ShowModal } from '../../../../Components/LeavesSuggestion'
import { useMemo } from 'react'

const LeavesSuggestionsModals = (handleClose: (name: string) => void) => {
    const { t } = useTranslation()
    const modals = useMemo(() => {
        return [
            {
                id: 1,
                name: "showLeavesSuggestion",
                title: t("show"),
                children: <ShowModal />

            },
            {
                id: 2,
                name: "editLeavesSuggestion",
                title: t("edit"),
                children: <EditModal handleClose={handleClose} />
            },
            {
                id: 3,
                name: "Delete",
                title: t("Delete"),
                children: <DeleteModal
                    btnText={t("Delete")}
                    endPointConfig={{
                        invalidate: "get-all-leaves-suggestion",
                        queryKey: ["delete-leave-suggestion"],
                        endPoint: LeavesSuggestionService.delete
                    }}
                    handleClose={() => handleClose("Delete")}
                    successMsg={t("deleted-succssefully")}
                />
            }
        ]
    }, [handleClose])


    return { modals }
}

export default LeavesSuggestionsModals