import { FC, useCallback, useContext, useMemo } from "react"
import { useTranslation } from "react-i18next"
import useQueryPost from "../../Custom Hooks/useQueryPost"
import AdministrativeDecisionsService from "../../Services/AdministrativeDecisionsService"
import { FormikHelpers, FormikValues } from "formik"
import { TableContext } from "../../Context/TableContext"
import useQueryGet from "../../Custom Hooks/useQueryGet"
import { FormikContainer } from "../Forms"
import AddDecisionSchema from "../../Utils/Validations/MainPage/AddDecisionSchema"
import AddDecisionInputs from "../../Assets/Data/Dashboard/MainPage/AddDecisionInputs"

type EditModalProps = {
    handleClose: () => void
}

const EditModal: FC<EditModalProps> = ({ handleClose }) => {
    const { t } = useTranslation()
    const { recordId } = useContext(TableContext)
    const [handleSubmit] = useQueryPost(AdministrativeDecisionsService.editDecision,
        ["edit-administrative-decision"],
        {
            success: t("edited-succssefully"),
        }, ["administrative-decisions"]
    )
    const [decision] = useQueryGet(() => AdministrativeDecisionsService.getSingleDecision(recordId), ["get-single-decision", recordId], undefined, {
        modalLoading: true
    })
    const initialValues = useMemo(() => {
        return {
            title: decision?.data?.data?.title,
            body: decision?.data?.data?.body
        }
    }, [decision])
    const onSubmit = useCallback((values: FormikValues, onSubmitProps: FormikHelpers<FormikValues>) => {
        handleSubmit({ ...values, id: recordId })
            .then((_) => {
                onSubmitProps.setSubmitting(false)
                handleClose()
            })
            .catch((_) => {
                onSubmitProps.setSubmitting(false)
            })
    }, [])
    return (
        <>
            <FormikContainer
                initialValues={initialValues}
                onSubmit={onSubmit}
                arrayOfInputs={AddDecisionInputs().inputs}
                className=" "
                btnText={t("edit")}
                schema={AddDecisionSchema}
                isEnableReinit={true}
            />
        </>
    )
}

export default EditModal