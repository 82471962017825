import { Field, FieldProps } from "formik";
import "./UploadImg.css"
import { useTranslation } from "react-i18next";
import { RiPencilFill } from "react-icons/ri";
import { FC, memo, useState } from "react";
type UploadImgProps = {
    label: string,
    name: string
}
const UploadImg: FC<UploadImgProps> = ({ label, name }) => {
    const { t } = useTranslation()
    const [imagePreview, setImagePreview] = useState<string | null>(null);
    return (
        <>
            <Field name="image1" >
                {({ field, form }: FieldProps<any>) => (
                    <>
                        <div className='img-label'>{t(label)}</div>
                        <label htmlFor={label} className="img-input-con bg-full default-img" style={{
                            backgroundImage: imagePreview != null ? `url(${imagePreview})` :
                                form.values[name] != null ? `url(https://crmapi.aetc.ae/${form.values[name]})`
                                    : ""
                        }}>
                            <div className="change-img-btn flex-center">
                                <RiPencilFill className="icon" />
                            </div>
                            <input id={label} {...field} type="file" onChange={(e) => {
                                setImagePreview(URL.createObjectURL(e.target.files![0]));
                                form.setFieldValue(name, e.target.files![0])
                            }} />
                        </label>
                    </>
                )}
            </Field>
            <Field name={name} type="hidden" />
        </>
    )
}

export default memo(UploadImg) 