import { useTranslation } from "react-i18next"
import { BoxWithShadow } from "../../../Components"
import "./EmplyerOfTheMonth.css"
import Img from "../../../Assets/Images/Dashboard/MainPage/Employee of the month-amico.png"
import { AxiosResponse } from "axios"
import { FC } from "react"
type EmplyerOfTheMonthProps = {
    details: AxiosResponse | undefined
}
const EmplyerOfTheMonth: FC<EmplyerOfTheMonthProps> = ({ details }) => {
    const { i18n, t } = useTranslation()
    return (
        <div className="row mb-5">
            <div className="col-md-8 p-0 pr-1">
                <BoxWithShadow additionalClass="employer-of-the-month container-fluid">
                    <div className="row row-cols-2">
                        <div className="col">
                            <div className="text">
                                <h2>{t("Congratulations")} {details?.data?.data?.last_employee_of_the_month?.user?.name} 🎉</h2>
                                <p>
                                    {i18n.language == "en" ? details?.data?.data?.last_employee_of_the_month?.message_en : details?.data?.data?.last_employee_of_the_month?.message_ar}
                                </p>
                            </div>
                        </div>
                        <div className="col">
                            <div className="employer-of-the-month-col bg-full">
                                <img src={Img} alt="" />
                            </div>
                        </div>
                    </div>
                </BoxWithShadow>
            </div>
            <div className="col-md-4">
                <BoxWithShadow image={details != undefined ? details?.data?.data?.last_employee_of_the_month?.user?.image : undefined} additionalClass="employee-of-month-img container-fluid bg-full" />
            </div>
        </div>
    )
}

export default EmplyerOfTheMonth