import { BoxTitle, BoxWithShadow, MainBtn, ModalComponent } from "../../../Components"
import "./Holidays.css"
import { useTranslation } from "react-i18next";
import { ToastContainer } from "react-toastify";
import useModals from "../../../Custom Hooks/useModals";
import { CalendarModals } from "../../../Assets/Data/Dashboard/Calendar";
import { Calendar } from "../../../Components/Calendar";
import { useCallback } from "react";
const Holidays = () => {
    const { t, i18n } = useTranslation()
    const { show, handleShow, handleClose } = useModals({
        addOutdoorTask: false,
        addLeaveSuggestion: false,
        addLeave: false,
        showLeave: false,
        showSpecialEvent: false,
        showLeavesSuggestion: false,
        showOutdoorTask: false
    })
    const { modals } = CalendarModals(handleClose)
    const memoizedAddLeaveBtn = useCallback(() => {
        handleShow("addLeave")
    }, [])
    const memoizedAddOutdoor = useCallback(() => {
        handleShow("addOutdoorTask")
    }, [])
    const memoizedAddLeaveSuggBtn = useCallback(() => {
        handleShow("addLeaveSuggestion")
    }, [])
    const memoizedHandleClose = useCallback((name: string) => {
        handleClose(name)
    }, [show])
    const memoizedHandleShow = useCallback((name: string) => {
        handleShow(name)
    }, [])
    return (
        <>
            <BoxWithShadow additionalClass={`holidays-con holidays-main-con mt-5 ${i18n.language == "ar" && "ar"}`}>
                <div className="flex-between mb-5">
                    <BoxTitle title={t("Calender")} />
                    <div className={`flex-between holidays-buttons ${i18n.language == "ar" && "ar"}`}>
                        <MainBtn title={t("AddLeave")} onClick={memoizedAddLeaveBtn} />
                        <MainBtn title={t("AddOutdoortask")} onClick={memoizedAddOutdoor} />
                        <MainBtn title={t("AddLeaveSuggestion")} onClick={memoizedAddLeaveSuggBtn} />
                    </div>
                </div>
                <Calendar handleShow={memoizedHandleShow} />
                <ModalComponent arrayOfModals={modals} show={show} handleClose={memoizedHandleClose} />
                <ToastContainer />
            </BoxWithShadow>
        </>

    )
}

export default Holidays