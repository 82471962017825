import { useMemo } from "react";
import { InputsArrayTypes } from "../../../../../Models/InputsArrayTypes";
const SalaryIncreasesInputs = () => {
    const inputs: InputsArrayTypes[] = useMemo(() => {
        return [
            {
                id: 1,
                control: "date-picker",
                label: "date",
                name: "date",
                dateFormat: "YYYY-MM-DD"
            },
            {
                id: 2,
                control: "input",
                as: "",
                label: "amount",
                name: "amount",
            },
        ]
    }, [])
    return { inputs }
}

export default SalaryIncreasesInputs