import { useTranslation } from "react-i18next"
import { BoxTitle, BoxWithShadow, DynamicTable } from "../../../Components"
import "./PreviousAcceptedLeavesRequestsTable.css"
import useQueryGet from "../../../Custom Hooks/useQueryGet"
import LeavesServices from "../../../Services/LeavesServices"
import { PreviousAcceptedLeavesRequestsTableCols } from "../../../Assets/Data/Dashboard/LeavesRequests"
import { memo } from "react"

const PreviousAcceptedLeavesRequestsTable = () => {
    const { t } = useTranslation()
    const [acceptedLeaves, , , loading] = useQueryGet(LeavesServices.getAcceptedLeaves, ["get-accepted-leaves"], undefined, {
        mainLoading: true
    })

    return (
        <>
            <BoxWithShadow additionalClass={`container-fluid users mb-4`}>
                <div className="flex-between">
                    <BoxTitle title={t("PreviousAcceptedLeavesRequests")} />
                </div>
                <DynamicTable
                    loading={loading}
                    dataSource={acceptedLeaves?.data?.data}
                    columns={PreviousAcceptedLeavesRequestsTableCols(t).columns}
                />
            </BoxWithShadow>
        </>

    )
}

export default memo(PreviousAcceptedLeavesRequestsTable) 