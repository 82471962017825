import { FC, memo } from "react"
import "./DynamicTable.css"
import { Table, TablePaginationConfig } from "antd"
import { ColsType } from "../../Models/ColsType"
import { useTranslation } from "react-i18next"
type DynamicTableProps = {
    loading: boolean,
    columns: ColsType[],
    dataSource: any,
    onClick?: (record: any) => any,
    pagination?: false | TablePaginationConfig | undefined
}
const DynamicTable: FC<DynamicTableProps> = ({ dataSource, columns, loading, onClick, pagination }) => {
    const { i18n } = useTranslation()
    return (
        <div className={`table-con ${i18n.language == "ar" && "ar"}`}>
            <Table
                dataSource={dataSource}
                columns={columns}
                pagination={pagination}
                rowKey={obj => obj.id}
                loading={loading}
                onRow={(record) => {
                    return {
                        onClick: () => onClick && onClick(record.id),
                    };
                }}
            />
        </div>
    )
}

export default memo(DynamicTable) 