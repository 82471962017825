import { AuthAxios } from "./AxiosHandler"

class PayrollService {
    static endPoint = "payroll"
    static get() {
        return AuthAxios.get(`${PayrollService.endPoint}/get-data-to-export`)
    }
    static store() {
        return AuthAxios.post(`${PayrollService.endPoint}/store`)
    }
    static filteredData(date: string) {
        return AuthAxios.get(`${PayrollService.endPoint}${date == null ? "" : `/${date}`}`)
    }
    static verify() {
        return AuthAxios.get(`${PayrollService.endPoint}/verify-exported-payroll`)
    }
}
export default PayrollService