import * as Yup from "yup"
const ResetPasswordSchema = Yup.object().shape({
    password: Yup.string()
        .min(8, 'Password must be at least 8 characters')
        .max(50, 'Password cannot exceed 50 characters'),
    password_confirmation: Yup.string()
        .min(8, 'Password must be at least 8 characters')
        .max(50, 'Password cannot exceed 50 characters')
        .oneOf([Yup.ref('password'), ""], 'Passwords must match')

})
export default ResetPasswordSchema