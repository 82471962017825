import { useEffect } from "react"
import { DropDownType } from "../../../../Models/DropDownType"
import SaudiFlag from "../../../Images/saudi-arabia.svg"
import UnitedStatesFlag from "../../../Images/united-states.svg"
import i18nn from "../../../../i18n"
const LangData = () => {
    const changeLanguages = (lang: string) => {
        i18nn.changeLanguage(lang)
        document.body.dir = lang == "en" ? "ltr" : "rtl"
    }
    useEffect(() => {
        document.body.dir = i18nn.language == "en" ? "ltr" : "rtl"
    }, [i18nn])

    const items: DropDownType[] = [
        {
            id: 1,
            className: "languages",
            children: <><span><img src={SaudiFlag} alt="" /></span>العربية</>,
            onClick: () => changeLanguages("ar")
        },
        {
            id: 2,
            className: "languages",
            children: <><span><img src={UnitedStatesFlag} alt="" /></span>English</>,
            onClick: () => changeLanguages("en")
        }
    ]

    return { items }
}

export default LangData