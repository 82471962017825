import { useContext } from "react"
import { useTranslation } from "react-i18next"
import { TableContext } from "../../Context/TableContext"
import useQueryGet from "../../Custom Hooks/useQueryGet"
import AdministrativeDecisionsService from "../../Services/AdministrativeDecisionsService"


const ShowModal = () => {
    const { t } = useTranslation()
    const { recordId } = useContext(TableContext)
    const [decision] = useQueryGet(() => AdministrativeDecisionsService.getSingleDecision(recordId), ["get-single-decision", recordId], undefined, {
        modalLoading: true
    })
    return (
        <>
            <div className="flex-start-center-row mb-3 show-box">
                <label className="mb-0 mx-2">{t("name")} :  </label>
                <p>{decision?.data?.data?.user?.name}</p>
            </div>
            <div className="flex-start-center-row mb-3 show-box">
                <label className="mb-0 mx-2">{t("Title")} :  </label>
                <p>{decision?.data?.data?.title}</p>
            </div>
            <div className="flex-start-center-row mb-3 show-box">
                <label className="mb-0 mx-2">{t("Body")} :  </label>
                <p>{decision?.data?.data?.body}</p>
            </div>

        </>
    )
}

export default ShowModal