import { Dispatch, SetStateAction } from "react"
import { DropDownType } from "../../../../Models/DropDownType"

const ActionsDropdown = (id: number, handleShow: (name: string) => void, t: any, setRecordId: Dispatch<SetStateAction<number | null>>) => {
    const items: DropDownType[] = [
        {
            id: 1,
            children: t("show"),
            onClick: () => {
                setRecordId(id)
                handleShow("ShowEmployer")
            }
        },
        {
            id: 2,
            children: t("Delete"),
            onClick: () => {
                handleShow("Delete")
                setRecordId(id)
            }
        },
    ]
    return { items }
}

export default ActionsDropdown