import "./WelcomeBox.css"
import HandWaving from "../../../Assets/Images/Login/icons8-waving-hand-emoji-48.png"
import { useContext } from "react"
import { UserContext } from "../../../Context/UserContext"
import { useTranslation } from "react-i18next"
const WelcomeBox = () => {
    const { name } = useContext(UserContext)
    const { t } = useTranslation()
    return (
        <div className="welcome-to-dashboard flex-start-start-col bg-full">
            <div className="waving-hand-con flex-center">
                <img src={HandWaving} alt="waving-hand" />
            </div>
            <h6>{t("welcome")} {name}</h6>
        </div>
    )
}

export default WelcomeBox