import { useMemo } from "react"
import useQueryGet from "../../../../../Custom Hooks/useQueryGet"
import { InputsArrayTypes } from "../../../../../Models/InputsArrayTypes"
import UserService from "../../../../../Services/UserService"

const InputsArray = () => {
    const [users,] = useQueryGet(UserService.getAll, ["get-all-users"])
    const employerOptions = users?.data?.data?.map((user: any) => {
        return {
            id: user.id,
            title: user.name,
            value: user.id
        }
    })
    const inputs: InputsArrayTypes[] = useMemo(() => {
        return [
            {
                id: 1,
                control: "date-picker",
                label: "month",
                name: "month",
                dateFormat: "YYYY-MM",
                isTwoCols: true
            },
            {
                id: 2,
                control: "select",
                label: "employer",
                name: "user_id",
                arrayOfOptions: employerOptions,
                isTwoCols: true
            },
            {
                id: 3,
                control: "input",
                as: "textarea",
                label: "message",
                name: "message_en",
                isTwoCols: true
            },
            {
                id: 4,
                control: "input",
                as: "textarea",
                label: "MessageInArabic",
                name: "message_ar",
                isTwoCols: true
            }
        ]
    }, [employerOptions])
    return { inputs }
}

export default InputsArray

