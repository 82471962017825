import { RouteObject } from "react-router-dom";
import { MainLayout, WithLinksLayout } from "../../Components";
import { MainLinks } from "../../Assets/Data";
import { Departments, EditMainPage, ExportSalary, Holidays, HrDashboard, LeavesRequests, LeavesSuggestion, MainPage, OutdoorTasks, SpecialEvents, Users } from "../../Pages";
import PrivateRoutes from "../../Utils/PrivateRoutes/PrivateRoutes";
import ProfileManagementRoutes from "./ProfileManagementRoutes";
import UserProfileRoutes from "./UserProfileRoutes";

const DashboardRoutes: RouteObject[] = [

    {
        Component: PrivateRoutes,
        children: [
            {
                path: "/dashboard",
                element: WithLinksLayout({
                    Component: MainLayout,
                    linksArray: MainLinks,
                }),
                children: [
                    {
                        path: "user-mangement",
                        Component: Users
                    },
                    {
                        path: "Edit-Main-Page",
                        Component: EditMainPage
                    },
                    {
                        path: "Export-Salary",
                        Component: ExportSalary
                    },
                    {
                        path: "Departments",
                        Component: Departments
                    },
                    {
                        path: "special-events",
                        Component: SpecialEvents
                    },
                    {
                        path: "Leaves-Suggestion",
                        Component: LeavesSuggestion
                    },
                    {
                        index: true,
                        Component: MainPage
                    },

                    {
                        path: "HR-Dashbord",
                        Component: HrDashboard
                    },
                    {
                        path: "Calender",
                        Component: Holidays
                    },
                    {
                        path: "Leaves-Requests",
                        Component: LeavesRequests
                    },
                    {
                        path: "Outdoor-Tasks",
                        Component: OutdoorTasks
                    },
                    ...ProfileManagementRoutes,
                    ...UserProfileRoutes
                ]
            }
        ]
    }

]
export default DashboardRoutes