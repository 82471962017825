import { useTranslation } from "react-i18next"
import useQueryGet from "../../../Custom Hooks/useQueryGet"
import PayrollService from "../../../Services/PayrollService"
import "./SalaryAlert.css"
const SalaryAlert = () => {
    const [data] = useQueryGet(PayrollService.verify, ["verifiedPayroll"])
    const { t } = useTranslation()
    return (
        <>
            {data?.data?.data == false && <div className="salray-alert flex-center">
                {t("PleaseDont")}
            </div>}
        </>
    )
}

export default SalaryAlert