import { FC } from "react";
import { LinksProps } from "../../Models/LinksProps";


type WithLinksProps = {
    Component: React.ComponentType<any>;
    linksArray: LinksProps[];
}


const WithLinksLayout: FC<WithLinksProps> = ({ linksArray, Component }) => {
    return (
        <Component linksArray={linksArray}>

        </Component>
    )
}

export default WithLinksLayout