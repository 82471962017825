import "./Loading.css"
import Logo from "../../Assets/Images/AETC Logo white.svg"
import { useTranslation } from "react-i18next"
const Loading = () => {
    const { i18n, t } = useTranslation()
    return (
        <div
            className={`loading-container ${i18n.language == "ar" && "ar"}`}
        >
            <img src={Logo} alt="logo" />
            <p>
                {t("Loading")}
                <span className="spinner-border" role="status">
                </span>
            </p>

        </div>
    )
}

export default Loading