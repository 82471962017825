import { FC, memo } from "react"
import "./MainBtn.css"

type MainBtnProps = {
    title: string,
    onClick?: () => void
}

const MainBtn: FC<MainBtnProps> = ({ title, onClick, ...reset }) => {
    return (
        <a className="btn main-btn" {...reset} onClick={onClick}>{title}</a>
    )
}

export default memo(MainBtn) 