import { useTranslation } from "react-i18next"
import { BoxTitle, BoxWithShadow } from "../../../../Components"
import "./EditProfile.css"
import { useParams } from "react-router-dom"
import { FormikHelpers, FormikValues } from "formik"
import UserService from "../../../../Services/UserService"
import { useCallback, useContext, useMemo } from "react"
import { ToastContainer } from "react-toastify"
import { UserContext } from "../../../../Context/UserContext"
import { FormikContainer } from "../../../../Components/Forms"
import { InputsArray } from "../../../../Assets/Data/Dashboard/ProfileMangement/EditProfile"
import schema from "../../../../Utils/Validations/ProfileMangement/EditProfile/schema"
import useQueryPost from "../../../../Custom Hooks/useQueryPost"
const EditProfile = () => {
    const params = useParams()
    const { t } = useTranslation()
    const [handleSubmit,] = useQueryPost(params.id != null ? UserService.updateUser : UserService.UpdateProfile, ["update-user-profile"], {
        success: t("edited-succssefully"),
    }, ["get-user-by-id"])
    const { loginedUserId, user } = useContext(UserContext)
    const initialValues = useMemo(() => {
        return {
            name: user?.name || "",
            type: user?.type || "",
            date_of_birth: user?.date_of_birth || "",
            email: user?.email || "",
            mobile: user?.mobile || "",
            official_date_of_employment: user?.official_date_of_employment || "",
            employer: user?.employer || "",
            visa_id_number: user?.visa_id_number || "",
            visa_expiry_date: user?.visa_expiry_date || "",
            labor_card_issue_date: user?.labor_card_issue_date || "",
            labor_card_expiry_date: user?.labor_card_expiry_date || "",
            blood_type: user?.blood_type || "",
            base_salary: user?.base_salary || "",
        }
    }, [user])

    const onSubmit = useCallback((values: FormikValues, onSubmitProps: FormikHelpers<FormikValues>) => {
        if (params.id != null) {
            handleSubmit({ ...values, id: params.id })
                .then((_) => {
                    onSubmitProps.setSubmitting(false)
                })
                .catch((_) => {
                    onSubmitProps.setSubmitting(false)
                })
        }
        else {
            handleSubmit({ ...values, id: loginedUserId })
                .then((_) => {
                    onSubmitProps.setSubmitting(false)
                })
                .catch((_) => {
                    onSubmitProps.setSubmitting(false)
                })
        }
    }, [])

    return (
        <>
            <BoxWithShadow additionalClass="profile-details container-fluid edit-profile">
                <div className="flex-between">
                    <BoxTitle title={t("profile-details")} />
                </div>
                <div className="flex-start-start-col profile-details-box">
                    <FormikContainer
                        initialValues={initialValues}
                        onSubmit={onSubmit}
                        arrayOfInputs={InputsArray().inputs}
                        className=""
                        btnText={t("edit")}
                        OptionalConfig={{
                            isNeedCheck: true,
                            checkName: "type",
                            checkValue: "On-site"
                        }}
                        optionalFileds={[
                            "visa_expiry_date",
                            "visa_place_of_issue",
                            "labor_card_issue_date",
                            "labor_card_expiry_date",]}
                        schema={schema}
                        isEnableReinit={true}
                    />
                </div>
                <ToastContainer />
            </BoxWithShadow>
        </>

    )
}

export default EditProfile