import * as Yup from "yup"
const schema = Yup.object().shape({
    date: Yup.string().required(),
    start: Yup.string().required(),
    end: Yup.string().required(),
    why: Yup.string().required()
        .min(3, 'Minimum 3 symbols')
        .max(10000, 'Maximum 10000 symbols')
        .required('why is required'),
    where_to_go: Yup.string().required()
        .min(3, 'Minimum 3 symbols')
        .max(1000, 'Maximum 10000 symbols')
        .required('where_to_go is required')
})
export default schema