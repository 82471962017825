import { AuthAxios } from "./AxiosHandler"

class SpecialEventsService {
    static endPoint = "special-events"
    static getAll(page: number) {
        return AuthAxios.get(`${SpecialEventsService.endPoint}?page=${page}`)
    }
    static show(id: number | null) {
        return AuthAxios.get(`${SpecialEventsService.endPoint}/find/${id}`)
    }
    static store(body: any) {
        return AuthAxios.post(`${SpecialEventsService.endPoint}/store`, body)
    }
    static update(body: any) {
        return AuthAxios.post(`${SpecialEventsService.endPoint}/update`, body)
    }
    static delete(id: number) {
        return AuthAxios.post(`${SpecialEventsService.endPoint}/delete/${id}`)
    }
}
export default SpecialEventsService