import { MainBtn } from "../../../../Components"
import useQueryPost from "../../../../Custom Hooks/useQueryPost"
import LeavesServices from "../../../../Services/LeavesServices"

const LeavesRequestsTableCols = (t: (key: string) => string) => {
    const [handleSubmit] = useQueryPost(LeavesServices.acceptLeave, ["accept-leave"], {
        success: t("accepted-successfully")
    }, ["get-leaves-requests"])

    const acceptLeave = (id: any) => {
        handleSubmit(id)
    }
    const columns = [
        {
            title: t("name"),
            dataIndex: 'user',
            key: 'user',
            render: (_: null, record: any) => {
                return record?.user?.name
            }
        },
        {
            title: t("Position"),
            dataIndex: 'position',
            key: 'position',
        },
        {
            title: t("start"),
            dataIndex: 'start_date',
            key: 'start_date',
        },
        {
            title: t("end"),
            dataIndex: 'end_date',
            key: 'end_date',
        },
        {
            title: t("AdmissionDate"),
            dataIndex: 'admission_date',
            key: 'admission_date',
        },
        {
            title: t("actions"),
            dataIndex: 'id',
            key: 'id',
            render: (id: number) => {
                return <MainBtn title={t("Accept")}
                    onClick={() => acceptLeave(id)}
                />
            }
        },
    ]

    return { columns }
}

export default LeavesRequestsTableCols