import React, { createContext, Dispatch, FC, SetStateAction, useState } from "react";

export const TableContext = createContext<{
    recordId: number | null;
    setRecordId: Dispatch<SetStateAction<number | null>>;
    tableName: string | null;
    setTableName: Dispatch<SetStateAction<string | null>>;
    record: any,
    setRecord: Dispatch<SetStateAction<any>>

}>({
    recordId: null,
    setRecordId: () => { },
    tableName: null,
    setTableName: () => { },
    record: null,
    setRecord: () => { }

})
type TableContextProviderProps = {
    children: React.ReactNode
}
const TableContextProvider: FC<TableContextProviderProps> = ({ children }) => {
    const [recordId, setRecordId] = useState<number | null>(null)
    const [record, setRecord] = useState<any>(null)
    const [tableName, setTableName] = useState<string | null>(null)

    return (
        <TableContext.Provider value={{
            recordId,
            setRecordId,
            setTableName,
            tableName,
            setRecord,
            record

        }}>
            {children}
        </TableContext.Provider>
    )
}

export default TableContextProvider