import { FC } from "react"
import "./ShowDataDesign.css"
type ShowDataDesignProps = {
    label: string,
    data: string,
    type: string,
    isOptional?: boolean
}
const ShowDataDesign: FC<ShowDataDesignProps> = ({ label, data, type, isOptional }) => {
    if (type === "On-site" && isOptional === true) {
        return (
            <div className="row">
                <div className="col">
                    <h6>{label}</h6>
                </div>
                <div className="col">
                    <p className="m-0">{data}</p>
                </div>
            </div>
        )
    }
    else if (isOptional === undefined) {
        return (
            <div className="row">
                <div className="col">
                    <h6>{label}</h6>
                </div>
                <div className="col">
                    <p className="m-0">{data}</p>
                </div>
            </div>
        )
    }
    else {
        return <></>
    }

}

export default ShowDataDesign