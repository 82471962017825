
import { LiaSwatchbookSolid } from "react-icons/lia";
import { FaUniversity } from "react-icons/fa";
import { IoIosSchool } from "react-icons/io";
import { LiaSchoolSolid } from "react-icons/lia";
import { SiSemanticscholar } from "react-icons/si";
import { FaBookReader } from "react-icons/fa";
import { GiBlackBook, GiBookshelf } from "react-icons/gi";
const CheckIcons = (iconName: string) => {
    let icon = <FaUniversity size={30} />
    if (iconName == "FaUniversity") {
        icon = <FaUniversity size={30} className="icon" />
    }
    else if (iconName == "IoIosSchool") {
        icon = <IoIosSchool size={30} className="icon" />
    }
    else if (iconName == "LiaSchoolSolid") {
        icon = <LiaSchoolSolid size={30} className="icon" />
    }
    else if (iconName == "SiSemanticscholar") {
        icon = <SiSemanticscholar size={30} className="icon" />
    }
    else if (iconName == "FaBookReader") {
        icon = <FaBookReader size={30} className="icon" />
    }
    else if (iconName == "GiBlackBook") {
        icon = <GiBlackBook size={30} className="icon" />
    }
    else if (iconName == "GiBookshelf") {
        icon = <GiBookshelf size={30} className="icon" />
    }
    else {
        icon = <LiaSwatchbookSolid size={30} className="icon" />
    }
    return icon
}

export default CheckIcons