import FullCalendar from "@fullcalendar/react"
import dayGridPlugin from "@fullcalendar/daygrid"
import timeGridPlugin from "@fullcalendar/timegrid"
import interactionPlugin from "@fullcalendar/interaction";
import useQueryGet from "../../Custom Hooks/useQueryGet";
import LeavesServices from "../../Services/LeavesServices";
import { FC, memo, useCallback, useContext, useState } from "react";
import CheckModalToOpen from "../../Helpers/CheckModalToOpen";
import { TableContext } from "../../Context/TableContext";


type CalendarProps = {
    handleShow: (name: string) => void
}
const Calendar: FC<CalendarProps> = ({ handleShow }) => {
    const { setRecordId } = useContext(TableContext)
    const [today, setToday] = useState(new Date())
    const [startDate, setStartDate] = useState(new Date(today.getFullYear(), today.getMonth(), 1).toISOString())
    const [endDate, setEndDate] = useState(new Date(today.getFullYear(), today.getMonth() + 1, 0).toISOString())
    const [data,] = useQueryGet(() => LeavesServices.get(startDate, endDate), ["get-calendar", startDate, endDate],
        undefined, {
        mainLoading: true
    })
    const handleDatesSet = useCallback((info: any) => {
        const startDate = info.startStr;
        const endDate = info.endStr;
        setStartDate(startDate)
        setEndDate(endDate)
    }, [])
    const handleDate = useCallback((clickInfo: any) => {
        CheckModalToOpen(handleShow, clickInfo.event.extendedProps.class_name, clickInfo?.event?._def?.publicId, setRecordId)
    }, [])
    return (
        <>
            <FullCalendar
                eventClassNames="calendar-event"
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                initialView={"dayGridMonth"}
                events={data?.data?.data}
                eventClick={handleDate}
                datesSet={handleDatesSet}
            />
        </>

    )
}

export default memo(Calendar) 