import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const ExportSalaryTableCols = () => {
    const { t } = useTranslation()
    const columns = useMemo(() => {
        return [
            {
                title: t("name"),
                dataIndex: 'user_name',
                key: 'user_name'
            },
            {
                title: t("BaseSalary"),
                dataIndex: 'base_salary',
                key: 'base_salary',
            },
            {
                title: t("NetSalary"),
                dataIndex: 'net_salary',
                key: 'net_salary',
                render: (net_salary: number) => net_salary.toFixed(2)
            },
            {
                title: t("TotalBonus"),
                dataIndex: 'total_bonuses',
                key: 'total_bonuses',
                render: (total_bonuses: number) => total_bonuses.toFixed(2)
            },
            {
                title: t("TotalDeduction"),
                dataIndex: 'total_deductions',
                key: 'total_deductions',
                render: (total_deductions: number) => total_deductions.toFixed(2)

            },
            {
                title: t("TotalSalaryIncrease"),
                dataIndex: 'total_salary_increase',
                key: 'total_salary_increase',
                render: (total_salary_increase: number) => total_salary_increase.toFixed(2)

            },
            {
                title: t("PayrollDate"),
                dataIndex: 'payroll_date',
                key: 'payroll_date',

            },

        ]
    }, [])

    return { columns }
}

export default ExportSalaryTableCols