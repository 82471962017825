import { useTranslation } from 'react-i18next'
const LeavesCols = () => {
    const { t } = useTranslation()
    const cols = [
        {
            title: t("name"),
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: t("sick-leave"),
            dataIndex: 'sick_leaves_count',
            key: 'sick_leaves_count',
        },
        {
            title: t("administrative-leave"),
            dataIndex: 'remaining_administrative_leave',
            key: 'remaining_administrative_leave',
        },
        {
            title: t("leave-without-pay"),
            dataIndex: 'leaves_without_pay_count',
            key: 'leaves_without_pay_count',
        },
    ]

    return { cols }
}

export default LeavesCols