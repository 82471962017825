import "./HrDashboard.css"
import { ToastContainer } from "react-toastify";
import { EmployerOfTheMonthBox, LeavesBox } from "../../../Sections/HrDashboard";

const HrDashboard = () => {

    return (
        <>
            <LeavesBox />
            <EmployerOfTheMonthBox />
            <ToastContainer />
        </>

    )
}

export default HrDashboard