import React, { FC, useCallback, useContext, useEffect } from 'react'
import { FormikContainer } from '../Forms'
import { InputsArray } from '../../Assets/Data/Dashboard/SpecialEvents'
import useQueryPost from '../../Custom Hooks/useQueryPost'
import SpecialEventsService from '../../Services/SpecialEventsService'
import { useTranslation } from 'react-i18next'
import { FormikHelpers, FormikValues } from 'formik'
import schema from '../../Utils/Validations/SpecialEvents/schema'
import { LoaderContext } from '../../Context/LoaderContext'
const initialValues = {
    title: "",
    body: "",
    start: "",
    end: "",

}
type AddModalProps = {
    handleClose: (name: string) => void
}
const AddModal: FC<AddModalProps> = ({ handleClose }) => {
    const { t } = useTranslation()
    const { setterIsModalLoading, getIsModalLoading } = useContext(LoaderContext)
    const [handleAdd] = useQueryPost(SpecialEventsService.store, ["add-special-event"], {
        success: t("added-succssefully"),
    }, ["get-special-events"], () => {
        handleClose("addSpecialEvent")
    })

    useEffect(() => {
        if (getIsModalLoading() === true) {
            setterIsModalLoading(null)
        }
    }, [])
    const onSubmit = useCallback((values: FormikValues, onSubmitProps: FormikHelpers<FormikValues>) => {
        handleAdd(values)
            .then((_) => {
                onSubmitProps.setSubmitting(false)
            })
            .catch((_) => {
                onSubmitProps.setSubmitting(false)
            })
    }, [])

    return (
        <FormikContainer
            className=''
            arrayOfInputs={InputsArray().inputs}
            initialValues={initialValues}
            onSubmit={onSubmit}
            btnText={t("add")}
            schema={schema}
        />
    )
}

export default AddModal