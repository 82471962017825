import { ErrorMessage, Field, FieldProps } from 'formik'
import React, { FC, memo } from 'react'
import TextError from '../../TextError/TextError'
import { useTranslation } from 'react-i18next'
import "./UploadReport.css"

type UploadReportProps = {
    label: string,
    name: string
}
const UploadReport: FC<UploadReportProps> = ({ label, name }) => {
    const { t } = useTranslation()
    return (
        <>
            <Field name="image1">
                {({ field, form }: FieldProps<any>) => (
                    <>
                        <div className="mb-3 mt-3">
                            <label htmlFor="formFile" className="form-label">{t(label)}</label>
                            <input className="form-control taqrer" type="file" id="formFile"
                                onChange={(e) => {
                                    form.setFieldValue(name, e.target.files![0])
                                }} />
                        </div>
                    </>
                )}
            </Field>
            <Field name={name} type="hidden" />
            <ErrorMessage name={name} component={TextError} />
        </>
    )
}

export default memo(UploadReport) 