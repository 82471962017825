import { FC } from 'react';
import { PieChart, Pie, Cell } from 'recharts';
type PieChartEmployies = {
    onSite: number,
    remotely: number
}
const PieChartEmployies: FC<PieChartEmployies> = ({ onSite, remotely }) => {
    const data = [
        { name: 'On-site', value: onSite },
        { name: 'Remotely', value: remotely },
    ];
    const COLORS = ['white', 'rgba(255, 255, 255, 0.528)'];
    return (
        <PieChart width={170} height={170}>
            <Pie
                data={data}
                labelLine={false}
                outerRadius={80} // Outer radius of the pie chart
                innerRadius={40} // Inner radius to create a hole
                fill="#8884d8"
                dataKey="value"
            >
                {data.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
            </Pie>
        </PieChart>
    )
}

export default PieChartEmployies