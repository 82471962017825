import { useMemo } from "react";
import { InputsArrayTypes } from "../../../../Models/InputsArrayTypes";
const InputsArray = () => {
    const inputs: InputsArrayTypes[] = useMemo(() => {
        return [
            {
                id: 1,
                control: "date-picker",
                label: "start",
                name: "start_date",
                dateFormat: "YYYY-MM-DD"
            },
            {
                id: 2,
                control: "date-picker",
                label: "end",
                name: "end_date",
                dateFormat: "YYYY-MM-DD"
            }
        ]
    }, [])
    return { inputs }
}

export default InputsArray