import { useTranslation } from "react-i18next"
import { DropDownMenu } from "../../../../Components"
import ActionsDropdown from "./ActionsDropdown"
import { useContext } from "react"
import { TableContext } from "../../../../Context/TableContext"

const EmployerOfTheMonthCols = (handleShow: (name: string) => void) => {
    const { t } = useTranslation()
    const { setRecordId } = useContext(TableContext)
    const cols = [
        {
            title: t("name"),
            dataIndex: 'user',
            key: 'user',
            render: (user: any) => user.name
        },
        {
            title: t("month"),
            dataIndex: 'month',
            key: 'month',
        },
        {
            title: t("actions"),
            dataIndex: 'id',
            key: 'id',
            render: (id: number) => <DropDownMenu
                className="action-table"
                arrayOfItems={ActionsDropdown(id, handleShow, t, setRecordId).items}
                children={t("actions")}
            />


        },
    ]



    return { cols }
}

export default EmployerOfTheMonthCols