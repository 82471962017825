import "./Design.css"
import Logo from "../../../Assets/Images/AETC Logo.svg"
import AuthScreens from "../../../Assets/Images/Login/7015971.png"
const Design = () => {
    return (
        <div className="col right-col bg-full flex-center-col p-0">
            <div className="logo-con">
                <img src={Logo} alt="logo" />
            </div>
            <div className="auth-screens">
                <img src={AuthScreens} alt="auth-screens" />
            </div>
        </div>
    )
}

export default Design