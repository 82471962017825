import * as Yup from "yup"
const AddDecisionSchema = Yup.object().shape({
    title: Yup.string()
        .min(1, 'Minimum 3 symbols')
        .max(20, 'Maximum 20 symbols')
        .required('title is required'),
    body: Yup.string()
        .min(3, 'Minimum 3 symbols')
        .max(10000, 'Maximum 10000 symbols')
        .required('body is required'),

})
export default AddDecisionSchema