import { useTranslation } from "react-i18next"
import { BoxWithShadow } from "../../../Components"
import "./style.css"
import { Outlet } from "react-router-dom";
import { BannerDesign, Tabs } from "../../../Sections/ProfileMangement/Index";
const Index = () => {
    const { i18n } = useTranslation()
    return (
        <>
            <BoxWithShadow additionalClass={`container-fluid profile-banner ${i18n.language == "ar" && "ar"}`}>
                <BannerDesign />
                <Tabs />
            </BoxWithShadow>
            <Outlet />
        </>
    )
}

export default Index