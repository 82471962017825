import { FC, memo, useEffect, useMemo } from "react"
import FormikControl from "./FormikControl/FormikControl"
import { FormikProps, FormikValues, useField } from "formik"

type IsVisibleInputProps = {
    OptionalConfig: {
        isNeedCheck: boolean,
        checkName: string,
        checkValue: string
    },
    input: any,
    optionalFileds?: string[]
}

const IsVisibleInput: FC<IsVisibleInputProps> = ({ OptionalConfig, input, optionalFileds }) => {
    const field = useField(OptionalConfig?.checkName)
    const visibleInputs = useMemo(() => {
        if (field[1].value == OptionalConfig?.checkValue && optionalFileds?.includes(input.name)) {
            return <FormikControl
                key={input.id}
                control={input.control}
                name={input.name}
                label={input.label}
                as={input.as}
                arrayOfOptions={input.arrayOfOptions}
                isTwoCols={input.isTwoCols}
                type={input.type}
                dateFormat={input.dateFormat}
                multiSelectOptions={input.multiSelectOptions}
                displayValue={input.displayValue}
            />
        }
        else if (!optionalFileds?.includes(input.name)) {
            return <FormikControl
                key={input.id}
                control={input.control}
                name={input.name}
                label={input.label}
                as={input.as}
                arrayOfOptions={input.arrayOfOptions}
                isTwoCols={input.isTwoCols}
                type={input.type}
                dateFormat={input.dateFormat}
                multiSelectOptions={input.multiSelectOptions}
                displayValue={input.displayValue}
            />
        }
        else {
            return null
        }
    }, [field[1].value])
    return visibleInputs
}

export default memo(IsVisibleInput) 