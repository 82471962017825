import * as Yup from 'yup'
export const schema = Yup.object().shape({
    start_date: Yup.string().required('start date is required'),
    end_date: Yup.string().required('end date is required'),
    back_to_work_date: Yup.string().required('back to work date is required'),
    type: Yup.string().required('type is required'),
    position: Yup.string()
        .min(2, 'Minimum 3 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('position is required'),
    medical_report: Yup.string().nullable()
})
