

const SalaryTableCols = (t: (key: string) => string) => {
    const cols = [
        {
            title: t("BaseSalary"),
            dataIndex: 'base_salary',
            key: 'base_salary',
        },
        {
            title: t("NetSalary"),
            dataIndex: 'net_salary',
            key: 'net_salary',
        },
        {
            title: t("PayrollDate"),
            dataIndex: 'payroll_date',
            key: 'payroll_date',
        },
        {
            title: t("TotalBonus"),
            dataIndex: 'total_bonuses',
            key: 'total_bonuses',
        },
        {
            title: t("TotalDeduction"),
            dataIndex: 'total_deductions',
            key: 'total_deductions',
        },
        {
            title: t("TotalSalaryIncrease"),
            dataIndex: 'total_salary_increase',
            key: 'total_salary_increase',
        },
    ]
    return { cols }
}

export default SalaryTableCols