import { useTranslation } from "react-i18next"
import { DeleteModal } from "../../../../../Components"
import UserService from "../../../../../Services/UserService"
import { AddModal } from "../../../../../Components/ProfileMangement/FileMangement"
import { useMemo } from "react"


const FileMangementModals = (handleClose: (name: string) => void, id: any, t: (key: string) => string) => {
    const modals = useMemo(() => {
        return [
            {
                id: 1,
                name: "addFile",
                title: t("add"),
                children: <AddModal handleClose={handleClose} id={id} />
            },
            {
                id: 2,
                name: "Delete",
                title: t("Delete"),
                children: <DeleteModal
                    btnText={t("Delete")}
                    endPointConfig={{
                        invalidate: "get-all-files",
                        queryKey: ["delete-file"],
                        endPoint: UserService.deleteFileById
                    }}
                    handleClose={() => handleClose("Delete")}
                    successMsg={t("deleted-succssefully")}
                />
            }
        ]
    }, [handleClose])


    return { modals }
}

export default FileMangementModals