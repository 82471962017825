import { AuthAxios } from "./AxiosHandler"

class EmployerOfTheMonthService {
    static endPoint = "employee-of-the-month"
    static getAll() {
        return AuthAxios.get(`${EmployerOfTheMonthService.endPoint}`)
    }
    static getSingleEmployer(id: number | null) {
        return AuthAxios.get(`${EmployerOfTheMonthService.endPoint}/find/${id}`)
    }
    static store(body: any) {
        return AuthAxios.post(`${EmployerOfTheMonthService.endPoint}/store`, body)
    }
    static delete(id: number) {
        return AuthAxios.post(`${EmployerOfTheMonthService.endPoint}/delete/${id}`)
    }
}
export default EmployerOfTheMonthService