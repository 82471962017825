import { createBrowserRouter, RouteObject } from "react-router-dom";
import Root from "../Root";
import { Login } from "../Pages";
import DashboardRoutes from "./DashboardRoutes/DashboardRoutes";

const routes: RouteObject[] = [
    {
        path: "/",
        Component: Root,
        children: [
            {
                index: true,
                Component: Login
            },
            ...DashboardRoutes
        ]
    }
]
export const router = createBrowserRouter(routes)