import { Dispatch, SetStateAction } from "react"
import { DropDownType } from "../../../../../Models/DropDownType"


const BonusTableActionsDropdown = (id: number, handleShow: (name: string) => void, t: any, setRecordId: Dispatch<SetStateAction<number | null>>, setTableName: Dispatch<SetStateAction<string | null>>) => {
    const items: DropDownType[] = [

        {
            id: 1,
            children: t("edit"),
            onClick: () => {
                setRecordId(id)
                handleShow("editBonus")
            }
        },
        {
            id: 2,
            children: t("Delete"),
            onClick: () => {
                setTableName("Bouns")
                setRecordId(id)
                handleShow("Delete")
            }
        },
    ]

    return { items }
}

export default BonusTableActionsDropdown