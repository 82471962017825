import * as Yup from "yup"
const addSchema = Yup.object().shape({
    date: Yup.string().nullable(),
    amount: Yup.string().nullable(),
    description: Yup.string()
        .min(3, 'Minimum 3 symbols')
        .max(10000, 'Maximum 10000 symbols')
        .nullable(),

})
export default addSchema