import { useTranslation } from "react-i18next"
import Hand from "../../../Assets/Images/Login/icons8-waving-hand-emoji-48.png"
import AuthServices from "../../../Services/AuthServices"
import "./Form.css"
import { LoginInputs } from "../../../Assets/Data"
import { schema } from "../../../Utils/Validations/LoginSchema"
import { Authintication } from "../../../Helpers"
import useQueryPost from "../../../Custom Hooks/useQueryPost"
import { FormikValues, FormikHelpers } from "formik"
import { FormikContainer } from "../../../Components/Forms"

const initialValues = {
    email: "",
    password: ""
}
const LoginForm = () => {
    const { t } = useTranslation()
    const [handleSubmit] = useQueryPost(AuthServices.post, ["login"], {
        success: t("loginSuccessfully")
    }, undefined, (res) => {
        Authintication().SignIn(res)
    })
    const onSubmit = (values: FormikValues, onSubmitProps: FormikHelpers<FormikValues>) => {
        handleSubmit(values)
            .then((_) => {
                onSubmitProps.setSubmitting(false)
            })
            .catch((_) => {
                onSubmitProps.setSubmitting(false)
            })
    }
    return (
        <div className="col p-0 left-col  flex-center-col">
            <div className="text flex-center-col">
                <h2>{t("SignIn")}</h2>
                <p>{t("WelcomeToAetc")} <span><img src={Hand} alt="hand" /></span></p>
            </div>
            <FormikContainer
                initialValues={initialValues}
                onSubmit={onSubmit}
                schema={schema}
                className="flex-center-col login-form"
                arrayOfInputs={LoginInputs}
                btnText="SignIn"
            />
        </div>
    )
}

export default LoginForm