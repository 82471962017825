import { AuthAxios } from "./AxiosHandler"

class DepartmentsService {
    static endPoint = "departments"
    static getAll() {
        return AuthAxios.get(`${DepartmentsService.endPoint}`)
    }
    static addDepartment(body: any) {
        return AuthAxios.post(`${DepartmentsService.endPoint}/store`, body)
    }
    static editDepartment(body: any) {
        return AuthAxios.post(`${DepartmentsService.endPoint}/update`, body)
    }


}
export default DepartmentsService