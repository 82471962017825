const PreviousAcceptedLeavesRequestsTableCols = (t: (key: string) => string) => {
    const columns = [
        {
            title: t("name"),
            dataIndex: 'user',
            key: 'user',
            render: (user: any) => user?.name
        },
        {
            title: t("Position"),
            dataIndex: 'position',
            key: 'position',
        },
        {
            title: t("start"),
            dataIndex: 'start_date',
            key: 'start_date',
        },
        {
            title: t("end"),
            dataIndex: 'end_date',
            key: 'end_date',
        },
        {
            title: t("AdmissionDate"),
            dataIndex: 'admission_date',
            key: 'admission_date',
        }
    ]
    return { columns }
}

export default PreviousAcceptedLeavesRequestsTableCols