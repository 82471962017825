
import { useContext } from 'react';
import { DropDownType } from '../../../../../Models/DropDownType'
import { TableContext } from '../../../../../Context/TableContext';
const ActionsDropdown = (id: number, t: (key: string) => string, handleShow: (name: string) => void) => {
    const { setRecordId } = useContext(TableContext)
    const items: DropDownType[] = [
        {
            id: 1,
            children: t("edit"),
            onClick: () => {
                setRecordId(id)
                handleShow("editCourse")
            }
        },
        {
            id: 2,
            children: t("Delete"),
            onClick: () => {
                setRecordId(id)
                handleShow("Delete")
            }
        },
    ]

    return { items }
}

export default ActionsDropdown