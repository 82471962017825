import { useTranslation } from "react-i18next"
import { BoxTitle, BoxWithShadow, DynamicTable, MainBtn } from "../../../Components"
import { FC, useCallback } from "react"
import { SalaryIncreaseTableCols } from "../../../Assets/Data/Dashboard/ProfileMangement/Salary"

type SalaryIncreaseTableProps = {
    handleShow: (name: string) => void,
    salaryIncreases: any,
    loading: boolean
}
const SalaryIncreaseTable: FC<SalaryIncreaseTableProps> = ({ handleShow, salaryIncreases, loading }) => {
    const { t } = useTranslation()
    const { cols } = SalaryIncreaseTableCols(t, handleShow)
    const memoizedHandleShow = useCallback(() => {
        handleShow("addSalaryIncreases")
    }, [])
    return (
        <BoxWithShadow additionalClass="mb-3 users">
            <div className="flex-between-start">
                <BoxTitle title={t("SalaryIncrease")} />
                <MainBtn title={t("add")} onClick={memoizedHandleShow} />
            </div>
            <DynamicTable
                loading={loading}
                dataSource={salaryIncreases}
                columns={cols}
                pagination={false}
            />
        </BoxWithShadow>
    )
}

export default SalaryIncreaseTable