import { NavLink, useParams } from "react-router-dom"
import "./TabsItem.css"
import { useTranslation } from "react-i18next"
import { FC } from "react"
type TabsItemsProps = {
    to: string,
    title: string,
    isOptional?: boolean
}
const TabsItems: FC<TabsItemsProps> = ({ to, title, isOptional }) => {
    const { t } = useTranslation()
    const params = useParams()
    if (isOptional == true && params.id != null) {
        return (
            <li>
                <NavLink to={to} end={true}>
                    {t(title)}
                </NavLink>
            </li>
        )
    }
    else if (isOptional == false && params.id == null) {
        return (
            <li>
                <NavLink to={to} end={true}>
                    {t(title)}
                </NavLink>
            </li>
        )
    }
    else if (isOptional == null) {
        return (
            <li>
                <NavLink to={to} end={true}>
                    {t(title)}
                </NavLink>
            </li>
        )
    }
    else {
        return <></>
    }

}

export default TabsItems