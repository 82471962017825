import { Dispatch, SetStateAction } from 'react'
import { DropDownType } from '../../../../Models/DropDownType'
import { Link } from 'react-router-dom'
const ActionsDropdown = (id: number, handleShow: (name: string) => void, t: any, setRecordId: Dispatch<SetStateAction<number | null>>, setRecord: Dispatch<SetStateAction<any>>, record: any) => {
    const items: DropDownType[] = [
        {
            id: 1,
            children: <Link to={`/dashboard/userProfile/${id}`} >
                {t("view-profile")}
            </Link>,
        },
        {
            id: 2,
            children: t("edit"),
            onClick: () => {
                setRecordId(id)
                handleShow("editUser")
            }
        },
        {
            id: 3,
            children: t("ResetPassword"),
            onClick: () => {
                handleShow("resetPassword")
                setRecordId(id)
            }
        },
        {
            id: 4,
            children: record?.is_active == 1 ? t("deactivate") : t("activate"),
            onClick: () => {
                handleShow("Delete")
                setRecordId(id)
                setRecord(record)
            }
        },

    ]

    return { items }
}

export default ActionsDropdown