import { memo, useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useLocation } from "react-router-dom"
import "./PageTitle.css"


const PageTitle = () => {
    const [pageName, setPageName] = useState<string>("")
    const location = useLocation()
    const { t } = useTranslation()
    const pageTitle = useCallback(() => {
        if (location.pathname === "/dashboard" || location.pathname === "/Dashboard") {
            return "Main-Page"
        }
        else {
            return location.pathname.split("/")[2]
        }
    }, [location.pathname])
    return (
        <h2>{t(pageTitle())}</h2>
    )
}

export default memo(PageTitle)