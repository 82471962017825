import { Dispatch, SetStateAction } from 'react'
import { DropDownType } from '../../../../Models/DropDownType'
const ActionsDropdown = (id: number, handleShow: (name: string) => void, t: any, setRecordId: Dispatch<SetStateAction<number | null>>) => {
    const items: DropDownType[] = [
        {
            id: 1,
            children: t("show"),
            onClick: () => {
                setRecordId(id)
                handleShow("showOutdoorTask")
            }
        },
        {
            id: 2,
            children: t("edit"),
            onClick: () => {
                setRecordId(id)
                handleShow("editOutdoorTask")
            }
        },
        {
            id: 3,
            children: t("Delete"),
            onClick: () => {
                setRecordId(id)
                handleShow("Delete")
            }
        },

    ]

    return { items }
}

export default ActionsDropdown