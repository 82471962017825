import { BoxTitle, BoxWithShadow } from "../../../Components"
import "./EmployeChart.css"
import useQueryGet from "../../../Custom Hooks/useQueryGet";
import UserService from "../../../Services/UserService";
import { useTranslation } from "react-i18next";
import { PieChartEmployies } from "../../../Components/Users";


const EmployeChart = () => {
    const { t } = useTranslation()
    const [generalStatics] = useQueryGet(UserService.getGeneralStatistics, ["general-statistics"], undefined, {
        mainLoading: true
    })
    return (
        <>
            <BoxWithShadow additionalClass={`employe-chart-box container-fluid mb-3 blue mt-5`}>
                <BoxTitle title={t("employies")} />
                <div className="sales-con-flex">
                    <div className="sales-con-flex-col">
                        <p>{generalStatics?.data?.data?.on_site} <span className="percentage-circle percentage-circle-one"></span></p>
                        <p>{t("on-site")} </p>
                    </div>
                    <div className="sales-con-flex-col">
                        <p>{generalStatics?.data?.data?.remotely} <span className="percentage-circle percentage-circle-two"></span></p>
                        <p>{t("remotely")}</p>
                    </div>
                    <PieChartEmployies onSite={generalStatics?.data?.data?.on_site} remotely={generalStatics?.data?.data?.remotely} />
                </div>
            </BoxWithShadow>
        </>

    )
}

export default EmployeChart