import { useTranslation } from 'react-i18next'
import { AddModal, EditModal, ResetPasswordModal } from '../../../../Components/Users'
import useQueryGet from '../../../../Custom Hooks/useQueryGet'
import RolesService from '../../../../Services/RolesService'
import { DeleteModal } from '../../../../Components'
import UserService from '../../../../Services/UserService'
import { useContext, useMemo } from 'react'
import { TableContext } from '../../../../Context/TableContext'

const UsersModals = (users: any[], handleClose: (name: string) => void) => {
    const { t } = useTranslation()
    const { record } = useContext(TableContext)
    const [roles,] = useQueryGet(RolesService.getAll, ["get-roles"])
    const mangerOptions = useMemo(() => {
        return users?.map(user => {
            return {
                id: user.id,
                title: user.name,
                value: user.id
            }
        })
    }, [users])

    const modals = useMemo(() => [
        {
            id: 1,
            name: "addUser",
            title: t("add"),
            children: <AddModal
                roles={roles != undefined ? roles?.data?.data : undefined}
                mangerOptions={mangerOptions}
                handleClose={() => handleClose("addUser")}
            />
        },
        {
            id: 2,
            name: "editUser",
            title: t("edit"),
            children: <EditModal
                roles={roles != undefined ? roles?.data?.data : undefined}
                mangerOptions={mangerOptions}
                handleClose={() => handleClose("editUser")}
            />
        },
        {
            id: 3,
            name: "resetPassword",
            title: t("ResetPassword"),
            children: <ResetPasswordModal
                handleClose={() => handleClose("addUser")}
            />
        },
        {
            id: 4,
            name: "Delete",
            title: record?.is_active == 1 ? t("deactivate") : t("activate"),
            children: <DeleteModal
                btnText={record?.is_active == 1 ? t("deactivate") : t("activate")}
                endPointConfig={{
                    invalidate: "get-all-users",
                    queryKey: ["deactivate-user"],
                    endPoint: UserService.deactiveUser
                }}
                handleClose={() => handleClose("Delete")}
                successMsg={record?.is_active == 1 ? t("deactivated-successfully") : t("activated-successfully")}
            />
        }
    ], [handleClose])

    return { modals }
}

export default UsersModals