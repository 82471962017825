import { FC } from "react";
import "./ToastErrorCon.css"
interface ToastErrorConProps {
    children?: React.ReactNode;
}

const ToastErrorCon: FC<ToastErrorConProps> = ({ children }) => {
    return (
        <div className="error-con-toast">
            {children}
        </div>
    )
}

export default ToastErrorCon