import "./Tabs.css"
import { TabsLinks } from "../../../../Assets/Data/Dashboard/ProfileMangement/Index"
import { TabsItem } from "../../../../Components/ProfileMangement/Index"
import { useMemo } from "react"

const Tabs = () => {
    const { items } = TabsLinks()
    const renderLinks = useMemo(() => {
        return items.map(link => (
            <TabsItem to={link.to} title={link.title} key={link.id} isOptional={link.isOptional} />
        ))
    }, [])

    return (
        <ul className="tabs">
            {renderLinks}
        </ul>
    )
}

export default Tabs