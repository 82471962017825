import { FormikHelpers, FormikValues } from "formik"
import { useTranslation } from "react-i18next"
import { FormikContainer } from "../Forms"
import AddDecisionSchema from "../../Utils/Validations/MainPage/AddDecisionSchema"
import useQueryPost from "../../Custom Hooks/useQueryPost"
import AdministrativeDecisionsService from "../../Services/AdministrativeDecisionsService"
import { FC, memo, useCallback } from "react"
import AddDecisionInputs from "../../Assets/Data/Dashboard/MainPage/AddDecisionInputs"
type AddModalProps = {
    handleClose: () => void
}
const AddModal: FC<AddModalProps> = ({ handleClose }) => {
    const { t } = useTranslation()
    const [handleSubmit] = useQueryPost(AdministrativeDecisionsService.store,
        ["add-administrative-decision"],
        {
            success: t("added-succssefully"),
        }, ["administrative-decisions"]
    )
    const initialValues = {
        title: "",
        body: ""
    }
    const onSubmit = useCallback((values: FormikValues, onSubmitProps: FormikHelpers<FormikValues>) => {
        handleSubmit(values)
            .then((_) => {
                onSubmitProps.setSubmitting(false)
                handleClose()
            })
            .catch((_) => {
                onSubmitProps.setSubmitting(false)
            })
    }, [])
    return (
        <>
            <FormikContainer
                initialValues={initialValues}
                onSubmit={onSubmit}
                arrayOfInputs={AddDecisionInputs().inputs}
                className=" "
                btnText={t("add")}
                schema={AddDecisionSchema}
            />
        </>

    )
}

export default memo(AddModal) 