import { AuthAxios } from "./AxiosHandler"

class CoursesService {
    static endPoint = "courses-library"
    static get(id: any) {
        return AuthAxios.get(`${CoursesService.endPoint}/${id}`)
    }
    static add(body: any) {
        return AuthAxios.post(`${CoursesService.endPoint}/store`, body)
    }
    static find(id: any) {
        return AuthAxios.get(`${CoursesService.endPoint}/find/${id}`)
    }
    static edit(body: any) {
        return AuthAxios.post(`${CoursesService.endPoint}/update`, body)
    }
    static delete(id: any) {
        return AuthAxios.post(`${CoursesService.endPoint}/delete/${id}`)
    }

}
export default CoursesService