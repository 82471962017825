import { ColsType } from "../../../../Models/ColsType";
import { useTranslation } from "react-i18next";
import { DropDownMenu } from "../../../../Components";
import ActionsDropdown from "./ActionsDropdown";
import { useContext } from "react";
import { TableContext } from "../../../../Context/TableContext";
const UsersCols = (handleShow: (name: string) => void) => {
    const { t } = useTranslation()
    const { setRecordId, setRecord } = useContext(TableContext)
    const UsersColsData: ColsType[] = [
        {
            title: t("name"),
            dataIndex: 'name',
            key: 'name',
            render: (_: null, record: any) => <>
                <div className="flex-start-center-row">
                    <div className={`bg-full user-table-img ${record.image == null && "default-img"}`}
                        style={{
                            backgroundImage: record.image == null ? "" : `url(https://crmapi.aetc.ae/${record.image})`
                        }}></div>
                    <div className="flex-start-start-col">
                        <h2>{record.name}</h2>
                        <p>{record.email}</p>
                    </div>
                </div>
            </>
        },
        {
            title: t("mobile"),
            dataIndex: 'mobile',
            key: 'mobile',
        },
        {
            title: t("type"),
            dataIndex: 'type',
            key: 'type',
        },
        {
            title: t("is_active"),
            dataIndex: 'is_active',
            key: 'is_active',
            render: (isActive: any) => <div className="is-active">
                {isActive == 0 ? "non Active" : "Active"}
            </div>
        },
        {
            title: t("actions"),
            dataIndex: 'id',
            key: 'id',
            render: (id: number, record: any) => <>
                <DropDownMenu
                    children={t("Actions")}
                    className="action-table"
                    arrayOfItems={ActionsDropdown(id, handleShow, t, setRecordId, setRecord, record).items}
                />
            </>
        },
    ];

    return { UsersColsData }
}

export default UsersCols

