import { useTranslation } from "react-i18next"
import { BoxTitle, BoxWithShadow, DynamicTable, ModalComponent } from "../../../Components"
import "./OutdoorTasks.css"
import { ToastContainer } from "react-toastify"
import OutdoorTasksService from "../../../Services/OutdoorTasksService"
import useModals from "../../../Custom Hooks/useModals"
import { OutdoorTasksCols, OutdoorModals } from "../../../Assets/Data/Dashboard/OutdoorTasks"
import useQueryGet from "../../../Custom Hooks/useQueryGet"
import { useState } from "react"

const OutdoorTasks = () => {
    const [page, setPage] = useState<number>(1)
    const { i18n, t } = useTranslation()
    const { show, handleShow, handleClose } = useModals({
        addOutdoorTask: false,
        editOutdoorTask: false,
        showOutdoorTask: false

    })
    const { modals } = OutdoorModals(handleClose, t)
    const [dataSource, loading] = useQueryGet(() => OutdoorTasksService.get(page), ["get-all-outdoor-tasks", page], undefined, {
        mainLoading: true
    })
    const { columns } = OutdoorTasksCols(t, handleShow)
    return (
        <>
            <BoxWithShadow additionalClass={`container-fluid users ${i18n.language == "ar" && "ar"} mb-4`}>
                <div className="flex-between mb-5">
                    <BoxTitle title={t("Outdoor-Tasks")} />
                </div>
                <DynamicTable
                    loading={loading}
                    dataSource={dataSource?.data?.data?.data}
                    columns={columns}
                    pagination={{
                        pageSize: dataSource?.data?.data?.per_page,
                        total: dataSource?.data?.data?.total,
                        onChange: (page) => setPage(page)

                    }}
                />
                <ModalComponent arrayOfModals={modals} handleClose={handleClose} show={show} />
            </BoxWithShadow>

            <ToastContainer />
        </>

    )
}

export default OutdoorTasks