import { BoxTitle, BoxWithShadow, DynamicTable } from '../../../Components'
import { useTranslation } from 'react-i18next'
import useQueryGet from '../../../Custom Hooks/useQueryGet'
import UserService from '../../../Services/UserService'
import { LeavesCols } from '../../../Assets/Data/Dashboard/HrDashboard'


const LeavesBox = () => {
    const { t } = useTranslation()
    const [dataSource, , , loading] = useQueryGet(UserService.getAll, ["users-leaves"], undefined, {
        mainLoading: true
    })
    const { cols } = LeavesCols()
    return (
        <>
            <BoxWithShadow additionalClass="mt-5">
                <BoxTitle title={t("Leaves")} />
                <DynamicTable
                    loading={loading}
                    dataSource={dataSource?.data?.data}
                    columns={cols}
                    pagination={false}
                />
            </BoxWithShadow>
        </>

    )
}

export default LeavesBox