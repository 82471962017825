import { RouteObject } from "react-router-dom";
import { ChangePassword, EditProfile, FileMangement, Index, Profile, Salary } from "../../Pages";

const ProfileManagementRoutes: RouteObject[] = [
    {
        path: "Profile-Mangement",
        Component: Index,
        children: [
            {
                index: true,
                Component: Profile,

            },
            {
                path: "edit-profile",
                Component: EditProfile,

            },
            {
                path: "File-Mangement",
                Component: FileMangement

            },
            {
                path: "Change-Password",
                Component: ChangePassword
            },
            {
                path: "Salary",
                Component: Salary
            }

        ]
    },
]
export default ProfileManagementRoutes