import "./ModalLoader.css"

const ModalLoader = () => {
    return (
        <div className='modal-loading flex-center'>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </div>
    )
}

export default ModalLoader