
import { useContext } from 'react';
import { DropDownType } from '../../../../../Models/DropDownType'
import { saveAs } from 'file-saver';
import { TableContext } from '../../../../../Context/TableContext';
const ActionsDropdown = (id: number, path: string, desc: string, t: (key: string) => string, handleShow: (name: string) => void) => {
    const { setRecordId } = useContext(TableContext)
    const downloadFile = (file: string, desc: string) => {
        saveAs(file, desc)
    }
    const items: DropDownType[] = [
        {
            id: 1,
            children: t("Download"),
            onClick: () => {
                downloadFile(`https://crmapi.aetc.ae/${path}`, desc)
            }
        },
        {
            id: 2,
            children: t("Delete"),
            onClick: () => {
                setRecordId(id)
                handleShow("Delete")
            }
        },
    ]

    return { items }
}

export default ActionsDropdown