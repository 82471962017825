type LoginInputsProps = {
    id: number,
    control: string,
    label: string,
    as: string,
    name: string,
    type?: string
}
const LoginInputs: LoginInputsProps[] = [
    {
        id: 1,
        control: "input",
        label: "email",
        as: "",
        name: "email"
    },
    {
        id: 2,
        control: "input",
        label: "password",
        as: "",
        type: "password",
        name: "password"
    }

]
export default LoginInputs