import { AxiosResponse } from "axios";
import { useContext, useEffect, useMemo } from "react";
import { useQuery } from "react-query"
import { LoaderContext } from "../Context/LoaderContext";
type EndpointFunction = (data: any) => Promise<AxiosResponse<any, any>>;
const useQueryGet = (endPoint: EndpointFunction, queryKey: any[], onSuccess?: (res: AxiosResponse) => void, loadingConfig?: {
    modalLoading?: boolean,
    mainLoading?: boolean
}): [AxiosResponse<any, any> | undefined, boolean, any, boolean] => {
    const { setterIsModalLoading, setterIsMainLoading } = useContext(LoaderContext)
    const { data, isLoading, error, isFetching } = useQuery({
        queryKey: [...queryKey],
        queryFn: endPoint,
        onSuccess: (res) => {
            if (onSuccess) {
                onSuccess(res)
            }
            if (loadingConfig?.mainLoading) {
                setterIsMainLoading(false)
            }
            else if (loadingConfig?.modalLoading) {
                setterIsModalLoading(false)
            }
        },
    })
    useEffect(() => {
        if (loadingConfig?.modalLoading && isFetching == true) {
            setterIsModalLoading(true)
        }
        if (loadingConfig?.mainLoading && isFetching == true) {
            setterIsMainLoading(true)
        }
    }, [])
    return useMemo(() => [data, isFetching, error, isLoading], [data, isFetching, error, isLoading]);
}
export default useQueryGet