
import { AuthAxios } from "./AxiosHandler"

class OutdoorTasksService {
    static endPoint = "outdoor-tasks"
    static get(page: number) {
        return AuthAxios.get(`${OutdoorTasksService.endPoint}?page=${page}`)
    }
    static approve(id: number) {
        return AuthAxios.post(`${OutdoorTasksService.endPoint}/approve/${id}`)
    }
    static delete(id: number) {
        return AuthAxios.post(`${OutdoorTasksService.endPoint}/delete/${id}`)
    }
    static show(id: number | null) {
        return AuthAxios.get(`${OutdoorTasksService.endPoint}/find/${id}`)
    }
    static store(body: any) {
        return AuthAxios.post(`${OutdoorTasksService.endPoint}/store`, body)
    }
    static edit(body: any) {
        return AuthAxios.post(`${OutdoorTasksService.endPoint}/update`, body)
    }
}
export default OutdoorTasksService