import DatePicker from "react-datepicker";
import { BoxTitle, BoxWithShadow, DynamicTable } from "../../../Components";
import "./SearchTable.css"
import { useTranslation } from "react-i18next";
import moment from "moment";
import { memo, useState } from "react";
import PayrollService from "../../../Services/PayrollService";
import { SearchTableCols } from "../../../Assets/Data/Dashboard/ExportSalary"
import useQueryGet from "../../../Custom Hooks/useQueryGet";

const SearchTable = () => {
    const [selectedDate, setSelectedDate] = useState<any>(null);
    const { t } = useTranslation()
    const { columns } = SearchTableCols()
    const [data, , , loading] = useQueryGet(() => PayrollService.filteredData(selectedDate), ["filter-salary-table", selectedDate])
    return (
        <BoxWithShadow additionalClass={`container-fluid users filtered-salary mb-4`}>
            <div className="flex-between-start ">
                <BoxTitle title={t("Salary")} />
                <div className="form-control">
                    <DatePicker
                        selected={selectedDate}
                        dateFormat="yyyy-MM"
                        className="form-control"
                        showYearDropdown
                        onChange={(val) => {
                            if (val) {
                                const formattedDate = moment(val).format('YYYY-MM');
                                setSelectedDate(formattedDate);
                            }
                        }}
                    />
                </div>
            </div>
            <DynamicTable
                loading={loading}
                dataSource={data?.data?.data}
                columns={columns}
                pagination={false}
            />
        </BoxWithShadow>
    )
}

export default memo(SearchTable) 