import { AuthAxios } from "./AxiosHandler"

class MainPageService {
    static endPoint = "main-page"
    static store(body: any) {
        return AuthAxios.post(`${MainPageService.endPoint}/update`, body)
    }
    static get() {
        return AuthAxios.get(`${MainPageService.endPoint}`)
    }
}
export default MainPageService