import { PublicAxios } from "./AxiosHandler"
type login = {
    email: string,
    password: string
}
class AuthServices {
    static endPoint = "login"
    static post(body: login) {
        return PublicAxios.post(`${AuthServices.endPoint}`, body)

    }
}
export default AuthServices