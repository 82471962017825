import { useTranslation } from 'react-i18next'
import { EditModal, ShowModal } from '../../../../Components/OutdoorTasks'
import { DeleteModal } from '../../../../Components'
import OutdoorTasksService from '../../../../Services/OutdoorTasksService'
import { useMemo } from 'react'

const OutdoorModals = (handleClose: (name: string) => void, t: (key: string) => string) => {
    const modals = useMemo(() => {
        return [
            {
                id: 1,
                name: "showOutdoorTask",
                title: t("show"),
                children: <ShowModal />
            },
            {
                id: 2,
                name: "editOutdoorTask",
                title: t("edit"),
                children: <EditModal handleClose={handleClose} />
            },
            {
                id: 3,
                name: "Delete",
                title: t("Delete"),
                children: <DeleteModal
                    btnText={t("Delete")}
                    endPointConfig={{
                        invalidate: "get-all-outdoor-tasks",
                        queryKey: ["delete-decision"],
                        endPoint: OutdoorTasksService.delete
                    }}
                    handleClose={() => handleClose("Delete")}
                    successMsg={t("deleted-succssefully")}
                />
            }
        ]
    }, [handleClose])


    return { modals }
}

export default OutdoorModals