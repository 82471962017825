import { InputsArrayTypes } from "../../../../Models/InputsArrayTypes"

const ResetPasswordArray: InputsArrayTypes[] = [
    {
        id: 1,
        control: "input",
        as: "",
        label: "password",
        name: "password",
    },
    {
        id: 2,
        control: "input",
        as: "",
        label: "password-confirmation",
        name: "password_confirmation",
    }
]
export default ResetPasswordArray