import { useParams } from "react-router-dom"

type LinksType = {
    id: number,
    to: string,
    title: string,
    isOptional?: boolean
}

const TabsLinks = () => {
    const params = useParams()
    const ProfileTo = params.id != null ? `/dashboard/userProfile/${params.id}` : "/dashboard/Profile-Mangement"
    const items: LinksType[] = [
        {
            id: 1,
            to: ProfileTo,
            title: "profile"
        },
        {
            id: 2,
            to: "edit-profile",
            title: "edit"
        },
        {
            id: 3,
            to: "Change-Password",
            title: "ChangePassword",
            isOptional: false
        },
        {
            id: 4,
            to: "Courses",
            title: "Courses",
            isOptional: true
        },
        {
            id: 5,
            to: "File-Mangement",
            title: "FileMangement"
        },
        {
            id: 6,
            to: "Salary",
            title: "Salary"
        }
    ]
    return { items }
}

export default TabsLinks