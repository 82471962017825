import { FormikHelpers, FormikValues } from "formik"
import { FormikContainer } from "../Forms"
import { ResetPasswordArray } from "../../Assets/Data/Dashboard/Users"
import ResetPasswordSchema from "../../Utils/Validations/Users/ResetPasswordSchema"
import { useTranslation } from "react-i18next"
import { FC, useContext } from "react"
import { TableContext } from "../../Context/TableContext"
import useQueryPost from "../../Custom Hooks/useQueryPost"
import UserService from "../../Services/UserService"

const initialValues = {
    password: "",
    password_confirmation: ""
}
type ResetPasswordModal = {
    handleClose: () => void
}

const ResetPasswordModal: FC<ResetPasswordModal> = ({ handleClose }) => {
    const { t } = useTranslation()
    const { recordId } = useContext(TableContext)
    const [handleSubmit, loading] = useQueryPost(UserService.resetPassword, ["reset-password"], {
        success: t("ResetPasswordSuccessfully"),
    }, ["get-all-users"], () => {
        handleClose()
    })
    const onSubmit = (values: FormikValues, onSubmitProps: FormikHelpers<FormikValues>) => {
        handleSubmit({ ...values, user_id: recordId })
            .then((_) => {
                onSubmitProps.setSubmitting(false)
            })
            .catch((_) => {
                onSubmitProps.setSubmitting(false)
            })
    }
    return (
        <FormikContainer
            initialValues={initialValues}
            onSubmit={onSubmit}
            className=""
            arrayOfInputs={ResetPasswordArray}
            schema={ResetPasswordSchema}
            btnText={t("ResetPassword")}
        />
    )
}

export default ResetPasswordModal