import { ErrorMessage, Field, useField } from "formik"
import "./Input.css"
import { TextError } from "../../"
import { useTranslation } from "react-i18next"
import { FC, memo } from "react"
import clsx from 'clsx'
type InputProps = {
    label: string,
    name: string,
    as: string,
    type?: string
    isTwoCols?: boolean,

}
const Input: FC<InputProps> = ({ label, name, as, isTwoCols, type }) => {
    const { t } = useTranslation()
    const field = useField(name)
    if (isTwoCols == true) {
        return <div className="row mb-3">
            <div className="col">
                <h6>{t(label)}</h6>
            </div>
            <div className="col">
                <div className="form-control dynamic-input">
                    <Field as={as} type={type} name={name} id={label} className={clsx(
                        'form-control',
                        { 'is-invalid': field[1].touched && field[1].error },
                        {
                            'is-valid': field[1].touched && !field[1].error,
                        }
                    )} />
                    <ErrorMessage name={name} component={TextError} />
                </div>
            </div>
        </div>
    }
    else {
        return (
            <div className="form-control dynamic-input">
                <label htmlFor={label} className="form-label">{t(label)}</label>
                <Field as={as} name={name} id={label} className={clsx(
                    'form-control',
                    { 'is-invalid': field[1].touched && field[1].error },
                    {
                        'is-valid': field[1].touched && !field[1].error,
                    }
                )} />
                <ErrorMessage name={name} component={TextError} />
            </div>
        )
    }

}

export default memo(Input) 