import * as Yup from "yup"
const schema = Yup.object().shape({
    start: Yup.string().required(),
    end: Yup.string().required(),
    title: Yup.string()
        .min(1, 'Minimum 3 symbols')
        .max(200, 'Maximum 200 symbols')
        .required('title is required'),
    body: Yup.string()
        .min(3, 'Minimum 3 symbols')
        .max(10000, 'Maximum 10000 symbols')
        .required('body is required'),

})
export default schema