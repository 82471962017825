import * as Yup from "yup"
const addSchema = Yup.object().shape({
    start_date: Yup.string().required("start_date is required"),
    end_date: Yup.string().required("end_date is required"),
    course_name: Yup.string()
        .min(3, 'Minimum 3 symbols')
        .max(300, 'Maximum 300 symbols')
        .required('course name is required'),
    objectives: Yup.string()
        .min(3, 'Minimum 3 symbols')
        .max(10000, 'Maximum 10000 symbols')
        .required('objectives is required'),
    meet_link: Yup.string()
        .min(3, 'Minimum 3 symbols')
        .max(300, 'Maximum 300 symbols')
        .nullable(),
    courses_platform_username: Yup.string()
        .min(3, 'Minimum 3 symbols')
        .max(300, 'Maximum 300 symbols')
        .nullable(),
    courses_platform_password: Yup.string()
        .min(3, 'Minimum 3 symbols')
        .max(300, 'Maximum 300 symbols')
        .nullable(),
    additional_information: Yup.string()
        .min(3, 'Minimum 3 symbols')
        .max(300, 'Maximum 300 symbols')
        .nullable(),
})
export default addSchema