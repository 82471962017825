import { useMemo } from "react"

type AddDecisionInputsType = {
    id: number,
    as: string,
    name: string,
    control: string,
    type?: string,
    label: string
}
const AddDecisionInputs = () => {
    const inputs: AddDecisionInputsType[] = useMemo(() => {
        return [
            {
                id: 1,
                as: "",
                name: "title",
                label: "Title",
                control: "input",
            },
            {
                id: 2,
                control: "input",
                label: "Description",
                as: "textarea",
                name: "body"
            }
        ]
    }, [])
    return { inputs }
}
export default AddDecisionInputs


