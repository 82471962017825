import { useMemo } from "react"
import { InputsArrayTypes } from "../../../../../Models/InputsArrayTypes"
const InputsArray = () => {
    const inputs: InputsArrayTypes[] = useMemo(() => {
        return [
            {
                id: 1,
                control: "input",
                as: "textarea",
                label: "DailyQuote",
                name: "daily_quote_en",
                isTwoCols: true
            },
            {
                id: 2,
                control: "input",
                as: "textarea",
                label: "DailyQuoteAr",
                name: "daily_quote_ar",
                isTwoCols: true
            },
            {
                id: 3,
                control: "input",
                as: "textarea",
                label: "companytext",
                name: "company_text_en",
                isTwoCols: true
            },
            {
                id: 4,
                control: "input",
                as: "textarea",
                label: "companyTextAr",
                name: "company_text_ar",
                isTwoCols: true
            }
        ]
    }, [])

    return { inputs }
}



export default InputsArray

